import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Divider,
  colors,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import DownloadingIcon from "@mui/icons-material/Downloading";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import UserAnalyticsChart from "./UserAnalyticsChart";
import axios from "axios";
import Logo from "../asset/Logo.png";
import emptyLogo from "../asset/empty-result.jpg";
import { styled } from "@mui/system";
import configParam from "../Config";
import { useReactToPrint } from "react-to-print";
import { Backpack } from "@mui/icons-material";
const Root = styled("div")({
  "@media print": {
    "& .printHeader": {
      display: "flex !important",
    },
  },
});

const containerStyle = {
  textAlign: "center",
  padding: "0 8px",
};

const trialHeadingStyle = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#000000",
  padding: "0 8px",
};

const textStyle = {
  color: "#616161",
  fontSize: "14px",
  fontFamily: "DM Sans, sans-serif",
};

const textValueStyle = {
  fontWeight: 800,
  fontSize: "18px",
  fontFamily: "DM Sans, sans-serif",
};
const totalTextValueStyle = {
  fontWeight: 800,
  fontSize: "18px",
  fontFamily: "DM Sans, sans-serif",
  color: "#0086DC",
};

const NewUserAnalytics = (props) => {
  const [error, setError] = useState(null);
  const ApiURL = configParam.API_URL;
  const [maxWidth, setMaxWidth] = useState("lg");
  const [analyticsdata, setAnalyticsdata] = useState([]);
  const [year, setYear] = useState("2024");
  const [rmcData, setRmcData] = useState([]);
  const [jobTypeData, setJobTypeData] = useState([]);
  const [successRMC, setSuccessRMC] = useState(0);
  const [failRMC, setFailRMC] = useState(0);
  const [successJobType, setSuccessJobType] = useState(0);
  const [failJobType, setFailJobType] = useState(0);

  const handleYearChange = (newYear) => {
    setYear(newYear);
  };

  console.log("propssss", props.data);

  const [statusCount, setStatusCount] = useState({
    Approved: 0,
    ReSubmit: 0,
    Rejected: 0,
    "Awaiting Approval": 0,
  });
  const [statusComment, setStatusComment] = useState({ Success: 0, Fail: 0 });
  const componentRef = useRef();
  const contentToPrint = useRef(null);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        //         console.log("yearrrr",year);
        //         const response = await axios.get(`${ApiURL}get_trial_info_project_type/`, {
        //           params: {
        //             fromDate: year ? `${year}-01-01` : "",
        //             toDate: year ? `${year}-12-31` : "",
        //             user_id: props.data.id,
        //           },
        //         });

        //         if (response.data.length > 0) {

        //           console.log("PROJECT TYPE", response);

        //           // Define the project types to search for
        // const projectTypes = ["RMC", "Job type"];

        // const filteredRmcData = [];
        // const filteredJobTypeData = [];

        // // Filter data based on project type and store in corresponding arrays
        // response.data.forEach(item => {
        //     if (projectTypes.includes("RMC") && item.project_type.includes("RMC")) {
        //       filteredRmcData.push(item);
        //     }else{
        //       filteredJobTypeData.push(item);
        //     }
        // });

        // // Output the results
        // console.log('RMC Data:', filteredRmcData);
        // console.log('Job Type Data:', filteredJobTypeData);
        //    // Update state variables
        //    setRmcData(filteredRmcData);
        //    setJobTypeData(filteredJobTypeData);

        //           const monthlyStatusCounts = monthNames.reduce((acc, month, index) => {
        //             const monthData = response.data.find(
        //               (item) => item.period === `${year}-${String(index + 1).padStart(2, '0')}`
        //             );

        //             acc.push({
        //               month,
        //               Total: monthData ? parseInt(monthData.trials_count, 10) : 0,
        //               Approved: monthData ? parseInt(monthData.approved_count, 10) : 0,
        //               Resubmitted: monthData ? parseInt(monthData.resubmit_count, 10) : 0,
        //               Rejected: monthData ? parseInt(monthData.rejected_count, 10) : 0,
        //               Pending: monthData ? parseInt(monthData.pending_count, 10) : 0,
        //               Success: monthData ? parseInt(monthData.success_count, 10) : 0,
        //               Fail: monthData ? parseInt(monthData.fail_count, 10) : 0,
        //             });
        //             return acc;
        //           }, []);
        //           setStatusCount(monthlyStatusCounts);
        //         }

        const response = await axios.get(
          `${ApiURL}get_trial_info_project_type/`,
          {
            params: {
              fromDate: year ? `${year}-01-01` : "",
              toDate: year ? `${year}-12-31` : "",
              user_id: props.data.id,
            },
          }
        );

        if (response.data.length > 0) {
          console.log("PROJECT TYPE", response);

          // Define the project types to search for
          const projectTypes = ["RMC", "Job type"];

          const filteredRmcData = [];
          const filteredJobTypeData = [];

          // Filter data based on project type and year, and store in corresponding arrays
          response.data.forEach((item) => {
            const itemYear = item.period.split("-")[0]; // Extract the year from the period

            if (itemYear === year) {
              if (
                projectTypes.includes("RMC") &&
                item.project_type.includes("RMC")
              ) {
                filteredRmcData.push(item);
              } else {
                filteredJobTypeData.push(item);
              }
            }
          });

          // Output the filtered results
          console.log("Filtered RMC Data:", filteredRmcData);
          console.log("Filtered Job Type Data:", filteredJobTypeData);
          const successCountItemsRMC = filteredRmcData.filter(
            (item) => item.success_count === "1"
          );

          const successCountItemsJobType = filteredJobTypeData.filter(
            (item) => item.success_count === "1"
          );

          const failCountItemsRMC = filteredRmcData.filter(
            (item) => item.fail_count === "1"
          );

          const failCountItemsJobType = filteredJobTypeData.filter(
            (item) => item.fail_count === "1"
          );
          setSuccessRMC(successCountItemsRMC);
          setSuccessJobType(successCountItemsJobType);
          setFailRMC(failCountItemsRMC);
          setFailJobType(failCountItemsJobType);

          setRmcData(filteredRmcData);
          setJobTypeData(filteredJobTypeData);
        } else {
          console.log("No data found for the selected year.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [year, props.data.id]);

  // React-to-print hook for handling PDF printing
  const handlePrint = useReactToPrint({
    documentTitle: "User Analytics Report",
    onBeforePrint: () => console.log("Preparing to print..."),
    onAfterPrint: () => console.log("Printing completed."),
    removeAfterPrint: true,
    content: () => componentRef.current,
  });

  const handleSuccessFail = async () => {
    let url = ApiURL + "successFail_analytics/" + props.data.id;
    try {
      const res = await axios.get(url);
      if (res.data.length > 0) {
        const statusComments = res.data.reduce(
          (acc, item) => {
            if (item.status === "Success") {
              acc.Success += 1;
            } else if (item.status === "Fail") {
              acc.Fail += 1;
            }
            return acc;
          },
          { Success: 0, Fail: 0 }
        );
        setStatusComment(statusComments);
      } else {
        setStatusComment({ Success: 0, Fail: 0 });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("No data found for the specified parameters");
      } else {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
      }
    }
  };

  const fetchData = async () => {
    // if (props.open === true) {
    const url = `${ApiURL}user_analytics/${props.data.id}`;
    try {
      const res = await axios.get(url);
      if (res.data.length > 0) {
        setAnalyticsdata(res.data);
        const statusCounts = res.data.reduce(
          (acc, item) => {
            const { submission_status } = item;
            if (submission_status in acc) {
              acc[submission_status]++;
            } else {
              acc[submission_status] = 1;
            }
            return acc;
          },
          {
            Approved: 0,
            Resubmitted: 0,
            Rejected: 0,
            "Awaiting Approval": 0,
          }
        );
        setStatusCount(statusCounts);
        // setStatusComment(statusComments);
      } else {
        setAnalyticsdata([]);
        setStatusCount({
          Approved: 0,
          Resubmitted: 0,
          Rejected: 0,
          "Awaiting Approval": 0,
        });
        setStatusComment({ Success: 0, Fail: 0 });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("No data found for the specified parameters");
      } else {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
      }
    }
  };

  useEffect(() => {
    setError(null);
    fetchData();
    handleSuccessFail();
  }, [props.data.id]);

  return (
    <React.Fragment>
      {error ? (
        <Dialog
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
          fullWidth={true}
          maxWidth={"xs"}
          // PaperProps={{
          //   style: {
          //     width: '1000px', // Adjust width here
          //     maxWidth: 'none', // Removes the default max-width
          //   },
          // }}
        >
          <Box sx={{ textAlign: "center" }}>
            <img
              src={emptyLogo}
              style={{ maxWidth: "100%", maxHeight: "220px" }}
              alt="no data"
            />
            <Typography
              variant="h3"
              sx={{
                fontSize: "22px",
                color: "#f4a019",
                fontWeight: "bold",
                marginBottom: "16px",
              }}
            >
              No Data Found
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                marginBottom: "16px",
                color: "#999",
              }}
            >
              We cannot find Trial Data
            </Typography>
          </Box>
        </Dialog>
      ) : (
        <Dialog
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
          fullWidth={true}
          maxWidth={"xl"}
        >
          <div  ref={componentRef}>
          <DialogTitle id="user-analytics">
            {/* <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              User Analytics
            </Typography> */}

            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                color: "#272D36",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              {props.data.first_name} {props.data.last_name}
            </Typography>

            <Typography
              sx={{
                fontWeight: "normal",
                fontSize: "18px",
                marginTop: "10px",
                color: "#1B1F26B8",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              {props.data?.name
                ? `${props.data.name.toUpperCase()} ZONE - PERFORMANCE ANALYTICS ${year}`
                : `ZONE - PERFORMANCE ANALYTICS ${year}`}
            </Typography>
          </DialogTitle>

          <DialogContent ref={componentRef}>
            <Root>
              <Grid
                container
                style={{
                  background: "#EFEFEF", // Example background color
                  borderRadius: "8px", // Adjust the radius as needed
                  padding: "16px", // Optional: to add some space inside the container
                }}
              >
                {/* First Grid Item */}
                <Grid
                  item
                  xs={12 / 5} // 1/5 of the space
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {/* Content inside this Grid item */}
                  <Grid container direction="column">
                    {/* Number of Trials Title */}
                    <Grid item>
                      <Typography style={trialHeadingStyle}>
                        Number of Trials
                      </Typography>
                    </Grid>
                    {/* Horizontal Layout for RMX, Job Site, and Total */}
                    <Grid item style={{ marginTop: "10px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>RMX</Typography>
                          <Typography style={textValueStyle}>
                            {rmcData?.length || 0}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>JOB SITE</Typography>
                          <Typography style={textValueStyle}>
                            {jobTypeData?.length || 0}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>TOTAL</Typography>
                          <Typography style={totalTextValueStyle}>
                            {rmcData.length > 0 || jobTypeData.length > 0
                              ? rmcData.length + jobTypeData.length
                              : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Vertical Divider */}
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      position: "absolute",
                      right: 0,
                      height: "100%",
                    }}
                  />
                </Grid>

                {/* Second Grid Item */}
                <Grid
                  item
                  xs={12 / 5} // 1/5 of the space
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {/* Content inside this Grid item */}
                  <Grid container direction="column">
                    <Grid item>
                      <Typography style={trialHeadingStyle}>
                        Successful Trials (%)
                      </Typography>
                    </Grid>
                    {/* Placeholder for additional data */}
                    <Grid item style={{ marginTop: "10px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>RMX</Typography>
                          <Typography style={textValueStyle}>
                            {successRMC.length > 0 ? successRMC.length : "0"}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>JOB SITE</Typography>
                          <Typography style={textValueStyle}>
                            {successJobType.length > 0
                              ? successJobType.length
                              : "0"}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>TOTAL</Typography>
                          <Typography style={totalTextValueStyle}>
                            {(successRMC.length > 0 ? successRMC.length : 0) +
                              (successJobType.length > 0
                                ? successJobType.length
                                : 0)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      position: "absolute",
                      right: 0,
                      height: "100%",
                    }}
                  />
                </Grid>

                {/* Third Grid Item */}
                <Grid
                  item
                  xs={12 / 5} // 1/5 of the space
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {/* Content inside this Grid item */}
                  <Grid container direction="column">
                    <Grid item>
                      <Typography style={trialHeadingStyle}>
                        Failed Trials (%)
                      </Typography>
                    </Grid>
                    {/* Placeholder for additional data */}
                    <Grid item style={{ marginTop: "10px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>RMX</Typography>
                          <Typography style={textValueStyle}>
                            {failRMC.length > 0 ? failRMC.length : "0"}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>JOB SITE</Typography>
                          <Typography style={textValueStyle}>
                            {failJobType.length > 0 ? failJobType.length : "0"}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>TOTAL</Typography>
                          <Typography style={totalTextValueStyle}>
                            {(failRMC.length > 0 ? failRMC.length : 0) +
                              (failJobType.length > 0 ? failJobType.length : 0)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      position: "absolute",
                      right: 0,
                      height: "100%",
                    }}
                  />
                </Grid>

                {/* Fourth Grid Item */}
                <Grid
                  item
                  xs={12 / 5} // 1/5 of the space
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {/* Content inside this Grid item */}
                  <Grid container direction="column">
                    <Grid item>
                      <Typography style={trialHeadingStyle}>
                        Success Ratio (%)
                      </Typography>
                    </Grid>
                    {/* Placeholder for additional data */}
                    <Grid item style={{ marginTop: "10px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>RMX</Typography>
                          <Typography style={totalTextValueStyle}>
                            {rmcData.length > 0
                              ? (
                                  (successRMC.length / rmcData.length) *
                                  100
                                ).toFixed(2) + "%"
                              : "0%"}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>JOB SITE</Typography>
                          <Typography style={textValueStyle}>
                            {jobTypeData.length > 0
                              ? (
                                  (successJobType.length / jobTypeData.length) *
                                  100
                                ).toFixed(2) + "%"
                              : "0%"}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>TOTAL</Typography>
                          <Typography style={totalTextValueStyle}>
                            {(
                              ((successRMC.length + successJobType.length) *
                                100) /
                                (rmcData.length + jobTypeData.length) || 0
                            ).toFixed(2) + "%"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      position: "absolute",
                      right: 0,
                      height: "100%",
                    }}
                  />
                </Grid>

                {/* Fifth Grid Item (No Divider) */}
                <Grid
                  item
                  xs={12 / 5} // 1/5 of the space
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Content inside this Grid item */}
                  <Grid container direction="column">
                    <Grid item>
                      <Typography style={trialHeadingStyle}>
                        Trials Per Day
                      </Typography>
                    </Grid>
                    {/* Placeholder for additional data */}
                    <Grid item style={{ marginTop: "10px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>RMX</Typography>
                          <Typography style={textValueStyle}>
                            {(rmcData?.length / 22).toFixed(2)}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>JOB SITE</Typography>
                          <Typography style={textValueStyle}>
                            {(jobTypeData?.length / 22).toFixed(2)}
                          </Typography>
                        </Grid>
                        <Grid item style={containerStyle}>
                          <Typography style={textStyle}>TOTAL</Typography>
                          <Typography style={totalTextValueStyle}>
                            {" "}
                            {(
                              (rmcData.length + jobTypeData.length) /
                              22
                            ).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Root>
          </DialogContent>
          <DialogContent >
            <Root>
              <UserAnalyticsChart
                statusComment={statusComment}
                props={props}
                year={year}
                onYearChange={handleYearChange}
              />
            </Root>
          </DialogContent>
          </div>
          <DialogActions>
            <Button
              variant="contained"
              style={{
                marginBottom: "10px",
                backgroundColor: "#FFC400",
                color: "#1A1B1F",
                marginRight: "16px",
              }}
              onClick={() => {
                handlePrint(null, () => contentToPrint.current);
              }}
            >
              Save PDF
            </Button>
            <Button
              variant="outlined"
              sx={{
                marginBottom: "10px",
              }}
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default NewUserAnalytics;
