import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button, Grid } from "@mui/material";
import gqlQueries from "../Queries/Queries";
import configParam from "../Config";
import { ReactComponent as Delete } from "../asset/delete.svg";
import { ReactComponent as Edit } from "../asset/Edit.svg";
import UserForm from "./UserForm";
import SuccessPopup from "../input/SuccessPopup";
import DeletePopup from "../newVisit/DeletePopup";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { user } from "../recoil/atom";
import { useRecoilValue } from "recoil";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import UserAnalytics from "./UserAnalytics";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import NewUserAnalytics from "./NewUserAnalytics"

const RightContainer = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const [users, setusers] = useState([]);
  const currUser = useRecoilValue(user);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [userAnalytics, setUserAnalytics] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const ApiURL = configParam.API_URL;
  const userDet = localStorage.getItem("user");
  const userValue = JSON.parse(userDet);
  let roleID = JSON.parse(userDet);
  const [trialData,setTrialData]= useState([]);
  

  // const exportToExcel = async () => {
  //   // Create a new workbook
  //   const workbook = new ExcelJS.Workbook();

  //   // Function to style the header row
  //   const styleHeaderRow = (worksheet) => {
  //     worksheet.getRow(1).font = { bold: true };
  //     worksheet.getRow(1).alignment = {
  //       horizontal: "center",
  //       vertical: "middle",
  //     };
  //     worksheet.getRow(1).eachCell((cell) => {
  //       cell.fill = {
  //         type: "pattern",
  //         pattern: "solid",
  //         fgColor: { argb: "FFFFE0B2" }, // light orange background
  //       };
  //       cell.border = {
  //         top: { style: "thin" },
  //         left: { style: "thin" },
  //         bottom: { style: "thin" },
  //         right: { style: "thin" },
  //       };
  //     });
  //   };

  //   // Function to add data to a worksheet
  //   const addDataToSheet = (worksheet, data) => {
  //     worksheet.columns = [
  //       { header: "SG ID", key: "sgid", width: 30 },
  //       { header: "First Name", key: "first_name", width: 30 },
  //       { header: "Last Name", key: "last_name", width: 30 },
  //       { header: "Zone", key: "zone_name", width: 15 },
  //       { header: "Role", key: "role", width: 15 },
  //       { header: "Total Trials", key: "trials_count", width: 20 },
  //       { header: "Approved Trials", key: "approved_count", width: 20 },
  //       { header: "Pending Trials", key: "pending_count", width: 20 },
  //       { header: "Rejected Trials", key: "rejected_count", width: 20 },
  //       { header: "Resubmit Trials", key: "resubmit_count", width: 20 },
  //       { header: "Success Trials", key: "success_count", width: 20 },
  //       { header: "Failed Trials", key: "fail_count", width: 20 },
  //     ];

  //     styleHeaderRow(worksheet);

  //     data.forEach((item) => {
  //       worksheet.addRow(item);
  //     });
  //   };

  //   // Fetch data and add rows
  //   try {
  //     const url = ApiURL + "allUserAnalyticsChart";
  //     const response = await axios.get(url);
  //     const allData = response.data;

  //     // Separate overall data and month-wise data
  //     const overallData = allData.filter((item) => item.period === "Overall");
  //     const monthWiseData = allData.filter((item) => item.period !== "Overall");

  //     // Sort months in ascending order (e.g., "2023-04", "2023-05", ...)
  //     const months = [
  //       ...new Set(monthWiseData.map((item) => item.period)),
  //     ].sort();

  //     // Add overall data to a separate sheet
  //     const overallSheet = workbook.addWorksheet("Overall Trials");
  //     addDataToSheet(overallSheet, overallData);

  //     // Add month-wise data to separate sheets
  //     months.forEach((month) => {
  //       const monthData = monthWiseData.filter((item) => item.period === month);
  //       const monthSheet = workbook.addWorksheet(month);
  //       addDataToSheet(monthSheet, monthData);
  //     });

  //     // Create buffer
  //     const buffer = await workbook.xlsx.writeBuffer();

  //     // Create a blob from the buffer and save it using file-saver
  //     const blob = new Blob([buffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     saveAs(blob, "all_users_trial_data.xlsx");
  //   } catch (error) {
  //     console.log("get_current_users", error);
  //   }
  // };



  const exportToAllExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Overall Data');
  
    // Define columns
    worksheet.columns = [
      { header: 'S.No', key: 'serialNumber', width: 10 },
      { header: 'SGID', key: 'sgid', width: 20 },
      { header: 'Region', key: 'region', width: 20 },
      { header: 'Name', key: 'name', width: 25 },
      { header: 'Number of Trials - RMX', key: 'numberOfTrialsRMX', width: 10 },
      { header: 'Number of Trials - Job Site', key: 'numberOfTrialsJobSite', width: 10 },
      { header: 'Number of Trials - Total', key: 'numberOfTrialsTotal', width: 10 },
  
      { header: 'Successful Trials - RMX', key: 'successfulTrialsRMX', width: 10 },
      { header: 'Successful Trials - Job Site', key: 'successfulTrialsJobSite', width: 10 },
      { header: 'Successful Trials - Total', key: 'successfulTrialsTotal', width: 10 },
  
      { header: 'Failed Trials - RMX', key: 'failedTrialsRMX', width: 10 },
      { header: 'Failed Trials - Job Site', key: 'failedTrialsJobSite', width: 10 },
      { header: 'Failed Trials - Total', key: 'failedTrialsTotal', width: 10 },
  
      { header: 'Success Ratio - RMX', key: 'successRatioRMX', width: 10 },
      { header: 'Success Ratio - Job Site', key: 'successRatioJobSite', width: 10 },
      { header: 'Success Ratio - Total', key: 'successRatioTotal', width: 10 },
  
      { header: 'Trials per Day - RMX', key: 'trialsPerDayRMX', width: 10 },
      { header: 'Trials per Day - Job Site', key: 'trialsPerDayJobSite', width: 10 },
      { header: 'Trials per Day - Total', key: 'trialsPerDayTotal', width: 10 },
    ];
  
    // Add merged cell for 'All data' header
    worksheet.mergeCells('A1:D1'); // Merging cells A1 to D1
    const allDataCell = worksheet.getCell('A1');
    allDataCell.value = 'All Data';
    allDataCell.alignment = { horizontal: 'center', vertical: 'middle' };
    allDataCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    allDataCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    allDataCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    // Add merged cells for 'Number of Trials' header
    worksheet.mergeCells('E1:G1'); // Merging cells E1 to G1
    const numberOfTrialsCell = worksheet.getCell('E1');
    numberOfTrialsCell.value = 'Number of Trials';
    numberOfTrialsCell.alignment = { horizontal: 'center', vertical: 'middle' };
    numberOfTrialsCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    numberOfTrialsCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    numberOfTrialsCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    // Add merged cells for 'Successful Trials' header
    worksheet.mergeCells('H1:J1'); // Merging cells H1 to J1
    const successfulTrialsCell = worksheet.getCell('H1');
    successfulTrialsCell.value = 'Successful Trials';
    successfulTrialsCell.alignment = { horizontal: 'center', vertical: 'middle' };
    successfulTrialsCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    successfulTrialsCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    successfulTrialsCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    worksheet.mergeCells('K1:M1'); // Merging cells K1 to M1
    const failedTrialsCell = worksheet.getCell('K1');
    failedTrialsCell.value = 'Failed Trials';
    failedTrialsCell.alignment = { horizontal: 'center', vertical: 'middle' };
    failedTrialsCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    failedTrialsCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    failedTrialsCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    worksheet.mergeCells('N1:P1'); // Merging cells N1 to P1
    const successRatioCell = worksheet.getCell('N1');
    successRatioCell.value = 'Success Ratio';
    successRatioCell.alignment = { horizontal: 'center', vertical: 'middle' };
    successRatioCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    successRatioCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    successRatioCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    worksheet.mergeCells('Q1:S1'); // Merging cells Q1 to S1
    const trialsPerDayCell = worksheet.getCell('Q1');
    trialsPerDayCell.value = 'Trials per Day';
    trialsPerDayCell.alignment = { horizontal: 'center', vertical: 'middle' };
    trialsPerDayCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    trialsPerDayCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    trialsPerDayCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };

   const row = worksheet.addRow([
      'S.No', // Placeholder for merged cells
      'SGID', // Placeholder for merged cells
      'Region', // Placeholder for merged cells
      'Name', // Placeholder for merged cells
  
      'RMX',             // Number of Trials - RMX
      'Job Site',        // Number of Trials - Job Site
      'Total',           // Number of Trials - Total
  
      'RMX',             // Number of Trials - RMX
      'Job Site',        // Number of Trials - Job Site
      'Total',           // Number of Trials - Total
  
      'RMX',             // Number of Trials - RMX
      'Job Site',        // Number of Trials - Job Site
      'Total',           // Number of Trials - Total
  
      'RMX',             // Number of Trials - RMX
      'Job Site',        // Number of Trials - Job Site
      'Total',           // Number of Trials - Total
  
      'RMX',             // Number of Trials - RMX
      'Job Site',        // Number of Trials - Job Site
      'Total',           // Number of Trials - Total
    ]);

    // Apply styles to the entire row
    row.eachCell((cell) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFF00' }, // Yellow background
  };
  cell.font = {
    color: { argb: 'FF0000' }, // Red text color
  };
});
  
    try {
      const API_URL = ApiURL + "get_all_user_info_project_type";
      const response = await axios.get(API_URL);
      const allData = response.data;
      console.log("allData", allData);
  
      // Separate data by period
      const overallData = allData.filter(item => item.period === "Overall");
      const monthlyData = allData.filter(item => item.period !== "Overall");
      console.log("monthly data", monthlyData);
  
      // Aggregate overall data
      const aggregateData = {};
  
      overallData.forEach(item => {
        const key = `${item.sgid}-${item.zone_name}-${item.first_name+" "+item.last_name}`;
        if (!aggregateData[key]) {
          aggregateData[key] = {
            numberOfTrialsRMX: 0,
            numberOfTrialsJobSite: 0,
            successfulTrialsRMX: 0,
            successfulTrialsJobSite: 0,
            failedTrialsRMX: 0,
            failedTrialsJobSite: 0,
          };
        }
       // Update counts based on project_type
       if (item.project_type && item.project_type.includes("RMC")) {
        aggregateData[key].numberOfTrialsRMX += parseInt(item.trials_count || 0);
        aggregateData[key].successfulTrialsRMX += parseInt(item.success_count || 0);
        aggregateData[key].failedTrialsRMX += parseInt(item.fail_count || 0);
        } else {
            aggregateData[key].numberOfTrialsJobSite += parseInt(item.trials_count || 0);
            aggregateData[key].successfulTrialsJobSite += parseInt(item.success_count || 0);
            aggregateData[key].failedTrialsJobSite += parseInt(item.fail_count || 0);
        }
      });
  
      // Add rows to worksheet
      Object.keys(aggregateData).forEach((key, index) => {
        const [sgid, region, name] = key.split('-');
        const data = aggregateData[key];
        worksheet.addRow({
          serialNumber: index + 1,
          sgid,
          region,
          name,
          numberOfTrialsRMX: data.numberOfTrialsRMX,
          numberOfTrialsJobSite: data.numberOfTrialsJobSite,
          numberOfTrialsTotal: data.numberOfTrialsRMX + data.numberOfTrialsJobSite,
          successfulTrialsRMX: data.successfulTrialsRMX,
          successfulTrialsJobSite: data.successfulTrialsJobSite,
          successfulTrialsTotal: data.successfulTrialsRMX + data.successfulTrialsJobSite,
          failedTrialsRMX: data.failedTrialsRMX,
          failedTrialsJobSite: data.failedTrialsJobSite,
          failedTrialsTotal: data.failedTrialsRMX + data.failedTrialsJobSite,
          successRatioRMX: ((data.successfulTrialsRMX / (data.numberOfTrialsRMX || 1)) * 100).toFixed(2) + '%',
          successRatioJobSite: ((data.successfulTrialsJobSite / (data.numberOfTrialsJobSite || 1)) * 100).toFixed(2) + '%',
          successRatioTotal: (((data.successfulTrialsRMX + data.successfulTrialsJobSite) / (data.numberOfTrialsRMX + data.numberOfTrialsJobSite || 1)) * 100).toFixed(2) + '%',
          trialsPerDayRMX: (data.numberOfTrialsRMX / 22).toFixed(2),
          trialsPerDayJobSite: (data.numberOfTrialsJobSite / 22).toFixed(2),
          trialsPerDayTotal: ((data.numberOfTrialsRMX + data.numberOfTrialsJobSite) / 22).toFixed(2)
        });
      });


        // Process monthly data
  const monthlyDataGrouped = monthlyData.reduce((acc, item) => {
    if (!acc[item.period]) {
      acc[item.period] = [];
    }
    acc[item.period].push(item);
    return acc;
  }, {});


    // Sort periods in ascending order
    const sortedPeriods = Object.keys(monthlyDataGrouped).sort();

  // Create a sheet for each month and add data
  sortedPeriods.forEach(period => {
    const monthlySheet = workbook.addWorksheet(`${period}`);


      // Define columns
      monthlySheet.columns = [
        { header: 'S.No', key: 'serialNumber', width: 10 },
        { header: 'SGID', key: 'sgid', width: 20 },
        { header: 'Region', key: 'region', width: 20 },
        { header: 'Name', key: 'name', width: 25 },
        { header: 'Number of Trials - RMX', key: 'numberOfTrialsRMX', width: 10 },
        { header: 'Number of Trials - Job Site', key: 'numberOfTrialsJobSite', width: 10 },
        { header: 'Number of Trials - Total', key: 'numberOfTrialsTotal', width: 10 },
    
        { header: 'Successful Trials - RMX', key: 'successfulTrialsRMX', width: 10 },
        { header: 'Successful Trials - Job Site', key: 'successfulTrialsJobSite', width: 10 },
        { header: 'Successful Trials - Total', key: 'successfulTrialsTotal', width: 10 },
    
        { header: 'Failed Trials - RMX', key: 'failedTrialsRMX', width: 10 },
        { header: 'Failed Trials - Job Site', key: 'failedTrialsJobSite', width: 10 },
        { header: 'Failed Trials - Total', key: 'failedTrialsTotal', width: 10 },
    
        { header: 'Success Ratio - RMX', key: 'successRatioRMX', width: 10 },
        { header: 'Success Ratio - Job Site', key: 'successRatioJobSite', width: 10 },
        { header: 'Success Ratio - Total', key: 'successRatioTotal', width: 10 },
    
        { header: 'Trials per Day - RMX', key: 'trialsPerDayRMX', width: 10 },
        { header: 'Trials per Day - Job Site', key: 'trialsPerDayJobSite', width: 10 },
        { header: 'Trials per Day - Total', key: 'trialsPerDayTotal', width: 10 },
      ];


       // Add merged cell for 'All data' header
       monthlySheet.mergeCells('A1:D1'); // Merging cells A1 to D1
    const allDataCell = monthlySheet.getCell('A1');
    allDataCell.value = 'All Data';
    allDataCell.alignment = { horizontal: 'center', vertical: 'middle' };
    allDataCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    allDataCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    allDataCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    // Add merged cells for 'Number of Trials' header
    monthlySheet.mergeCells('E1:G1'); // Merging cells E1 to G1
    const numberOfTrialsCell = monthlySheet.getCell('E1');
    numberOfTrialsCell.value = 'Number of Trials';
    numberOfTrialsCell.alignment = { horizontal: 'center', vertical: 'middle' };
    numberOfTrialsCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    numberOfTrialsCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    numberOfTrialsCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    // Add merged cells for 'Successful Trials' header
    monthlySheet.mergeCells('H1:J1'); // Merging cells H1 to J1
    const successfulTrialsCell = monthlySheet.getCell('H1');
    successfulTrialsCell.value = 'Successful Trials';
    successfulTrialsCell.alignment = { horizontal: 'center', vertical: 'middle' };
    successfulTrialsCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    successfulTrialsCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    successfulTrialsCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    monthlySheet.mergeCells('K1:M1'); // Merging cells K1 to M1
    const failedTrialsCell = monthlySheet.getCell('K1');
    failedTrialsCell.value = 'Failed Trials';
    failedTrialsCell.alignment = { horizontal: 'center', vertical: 'middle' };
    failedTrialsCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    failedTrialsCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    failedTrialsCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    monthlySheet.mergeCells('N1:P1'); // Merging cells N1 to P1
    const successRatioCell = monthlySheet.getCell('N1');
    successRatioCell.value = 'Success Ratio';
    successRatioCell.alignment = { horizontal: 'center', vertical: 'middle' };
    successRatioCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    successRatioCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    successRatioCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };
  
    monthlySheet.mergeCells('Q1:S1'); // Merging cells Q1 to S1
    const trialsPerDayCell = monthlySheet.getCell('Q1');
    trialsPerDayCell.value = 'Trials per Day';
    trialsPerDayCell.alignment = { horizontal: 'center', vertical: 'middle' };
    trialsPerDayCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    trialsPerDayCell.font = {
      color: { argb: 'FF0000' }, // Red text color
      bold: true
    };
    trialsPerDayCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      left: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Black border
      right: { style: 'thin', color: { argb: 'FF000000' } } // Black border
    };

    const row = monthlySheet.addRow([
      'S.No', 
      'SGID', 
      'Region', 
      'Name',
      'RMX', 
      'Job Site', 
      'Total',
      'RMX', 
      'Job Site', 
      'Total',
      'RMX', 
      'Job Site', 
      'Total',
      'RMX', 
      'Job Site', 
      'Total',
      'RMX', 
      'Job Site', 
      'Total'
    ]);
    
    // Apply styles to the entire row
row.eachCell((cell) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFF00' }, // Yellow background
  };
  cell.font = {
    color: { argb: 'FF0000' }, // Red text color
  };
});
    
      const monthData = monthlyDataGrouped[period];
      console.log("mnthData", monthData);

// Initialize aggregateData for monthly processing
const monthlyAggregateData = {};


// Loop through the monthData
monthData.forEach(item => {
  const key = `${item.sgid}-${item.zone_name}-${item.first_name + " " + item.last_name}`;
  
  // Initialize data for each unique key if not already present
  if (!monthlyAggregateData[key]) {
    monthlyAggregateData[key] = {
      numberOfTrialsRMX: 0,
      numberOfTrialsJobSite: 0,
      successfulTrialsRMX: 0,
      successfulTrialsJobSite: 0,
      failedTrialsRMX: 0,
      failedTrialsJobSite: 0,
    };
  }

  // Update counts based on project_type
  if (item.project_type && item.project_type.includes("RMC")) {
    monthlyAggregateData[key].numberOfTrialsRMX += parseInt(item.trials_count || 0);
    monthlyAggregateData[key].successfulTrialsRMX += parseInt(item.success_count || 0);
    monthlyAggregateData[key].failedTrialsRMX += parseInt(item.fail_count || 0);
  } else {
    monthlyAggregateData[key].numberOfTrialsJobSite += parseInt(item.trials_count || 0);
    monthlyAggregateData[key].successfulTrialsJobSite += parseInt(item.success_count || 0);
    monthlyAggregateData[key].failedTrialsJobSite += parseInt(item.fail_count || 0);
  }
});

// Add rows to the monthly sheet
Object.keys(monthlyAggregateData).forEach((key, index) => {
  const [sgid, region, name] = key.split('-');
  const data = monthlyAggregateData[key];

  // Add the aggregated data to the monthly sheet
  monthlySheet.addRow({
    serialNumber: index + 1,
    sgid,
    region,
    name,
    numberOfTrialsRMX: data.numberOfTrialsRMX,
    numberOfTrialsJobSite: data.numberOfTrialsJobSite,
    numberOfTrialsTotal: data.numberOfTrialsRMX + data.numberOfTrialsJobSite,
    successfulTrialsRMX: data.successfulTrialsRMX,
    successfulTrialsJobSite: data.successfulTrialsJobSite,
    successfulTrialsTotal: data.successfulTrialsRMX + data.successfulTrialsJobSite,
    failedTrialsRMX: data.failedTrialsRMX,
    failedTrialsJobSite: data.failedTrialsJobSite,
    failedTrialsTotal: data.failedTrialsRMX + data.failedTrialsJobSite,
    successRatioRMX: ((data.successfulTrialsRMX / (data.numberOfTrialsRMX || 1)) * 100).toFixed(2) + '%',
    successRatioJobSite: ((data.successfulTrialsJobSite / (data.numberOfTrialsJobSite || 1)) * 100).toFixed(2) + '%',
    successRatioTotal: (((data.successfulTrialsRMX + data.successfulTrialsJobSite) / (data.numberOfTrialsRMX + data.numberOfTrialsJobSite || 1)) * 100).toFixed(2) + '%',
    trialsPerDayRMX: (data.numberOfTrialsRMX / 22).toFixed(2),
    trialsPerDayJobSite: (data.numberOfTrialsJobSite / 22).toFixed(2),
    trialsPerDayTotal: ((data.numberOfTrialsRMX + data.numberOfTrialsJobSite) / 22).toFixed(2)
  });
});
  });

  
      // Generate Excel file buffer
      const buffer = await workbook.xlsx.writeBuffer();
  
      // Create a blob and trigger a download
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_performance_analytics.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  
  
  
  

  // const exportToAllExcel = async () => {
  //   // Create a new workbook
  //   const workbook = new ExcelJS.Workbook();
  
  //   // Function to style the header row
  //   const styleHeaderRow = (worksheet) => {
  //     worksheet.getRow(1).font = { bold: true };
  //     worksheet.getRow(1).alignment = {
  //       horizontal: "center",
  //       vertical: "middle",
  //     };
  //     worksheet.getRow(1).eachCell((cell) => {
  //       cell.fill = {
  //         type: "pattern",
  //         pattern: "solid",
  //         fgColor: { argb: "FFFFE0B2" }, // light orange background
  //       };
  //       cell.border = {
  //         top: { style: "thin" },
  //         left: { style: "thin" },
  //         bottom: { style: "thin" },
  //         right: { style: "thin" },
  //       };
  //     });
  //   };
  
  //   // Function to add data to a worksheet with the new design
  //   const addDataToSheet = (worksheet, data) => {
  //     worksheet.columns = [
  //       { header: "SG ID", key: "sgid", width: 15 },
  //       { header: "First Name", key: "first_name", width: 15 },
  //       { header: "Last Name", key: "last_name", width: 15 },
  //       { header: "Zone", key: "zone_name", width: 15 },
  //       { header: "Role", key: "role", width: 15 },
  
  //       // Number of Trials section
  //       { header: "RMX Trials", key: "rmx_trials", width: 15 },
  //       { header: "JobSite Trials", key: "jobsite_trials", width: 15 },
  //       { header: "Total Trials", key: "total_trials", width: 15 },
  
  //       // Successful Trials section
  //       { header: "RMX Success", key: "rmx_success", width: 15 },
  //       { header: "JobSite Success", key: "jobsite_success", width: 15 },
  //       { header: "Total Success", key: "total_success", width: 15 },
  
  //       // Failed Trials section
  //       { header: "RMX Fail", key: "rmx_fail", width: 15 },
  //       { header: "JobSite Fail", key: "jobsite_fail", width: 15 },
  //       { header: "Total Fail", key: "total_fail", width: 15 },
  
  //       // Success Ratio section
  //       { header: "RMX Success Ratio", key: "rmx_success_ratio", width: 20 },
  //       { header: "JobSite Success Ratio", key: "jobsite_success_ratio", width: 20 },
  //       { header: "Total Success Ratio", key: "total_success_ratio", width: 20 },
  
  //       // Trials per day section
  //       { header: "RMX Trials/Day", key: "rmx_trials_per_day", width: 20 },
  //       { header: "JobSite Trials/Day", key: "jobsite_trials_per_day", width: 20 },
  //       { header: "Total Trials/Day", key: "total_trials_per_day", width: 20 },
  //     ];
  
  //     styleHeaderRow(worksheet);
  
  //     data.forEach((item) => {
  //       const totalTrials = item.rmx_trials + item.jobsite_trials;
  //       const totalSuccess = item.rmx_success + item.jobsite_success;
  //       const totalFail = item.rmx_fail + item.jobsite_fail;
  //       const totalSuccessRatio = totalTrials > 0 ? (totalSuccess / totalTrials) * 100 : 0;
  
  //       // Assume trials per day is calculated; you may need to adjust based on your data
  //       const totalTrialsPerDay = totalTrials / item.days;  // 'days' should be part of your data
  
  //       worksheet.addRow({
  //         sgid: item.sgid,
  //         first_name: item.first_name,
  //         last_name: item.last_name,
  //         zone_name: item.zone_name,
  //         role: item.role,
  
  //         // Number of Trials
  //         rmx_trials: item.rmx_trials,
  //         jobsite_trials: item.jobsite_trials,
  //         total_trials: totalTrials,
  
  //         // Successful Trials
  //         rmx_success: item.rmx_success,
  //         jobsite_success: item.jobsite_success,
  //         total_success: totalSuccess,
  
  //         // Failed Trials
  //         rmx_fail: item.rmx_fail,
  //         jobsite_fail: item.jobsite_fail,
  //         total_fail: totalFail,
  
  //         // Success Ratio
  //         rmx_success_ratio: item.rmx_trials > 0 ? (item.rmx_success / item.rmx_trials) * 100 : 0,
  //         jobsite_success_ratio: item.jobsite_trials > 0 ? (item.jobsite_success / item.jobsite_trials) * 100 : 0,
  //         total_success_ratio: totalSuccessRatio,
  
  //         // Trials per Day
  //         rmx_trials_per_day: item.rmx_trials / item.days,
  //         jobsite_trials_per_day: item.jobsite_trials / item.days,
  //         total_trials_per_day: totalTrialsPerDay,
  //       });
  //     });
  //   };
  
  //   // Fetch data and add rows
  //   try {
  //     const url = ApiURL + "get_all_user_info_project_type";
  //     const response = await axios.get(url);
  //     const allData = response.data;
  
  //     // Separate overall data and month-wise data
  //     const overallData = allData.filter((item) => item.period === "Overall");
  //     const monthWiseData = allData.filter((item) => item.period !== "Overall");
  
  //     // Sort months in ascending order (e.g., "2023-04", "2023-05", ...)
  //     const months = [...new Set(monthWiseData.map((item) => item.period))].sort();
  
  //     // Add overall data to a separate sheet
  //     const overallSheet = workbook.addWorksheet("Overall Trials");
  //     addDataToSheet(overallSheet, overallData);
  
  //     // Add month-wise data to separate sheets
  //     months.forEach((month) => {
  //       const monthData = monthWiseData.filter((item) => item.period === month);
  //       const monthSheet = workbook.addWorksheet(month);
  //       addDataToSheet(monthSheet, monthData);
  //     });
  
  //     // Create buffer
  //     const buffer = await workbook.xlsx.writeBuffer();
  
  //     // Create a blob from the buffer and save it using file-saver
  //     const blob = new Blob([buffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     saveAs(blob, "all_users_trial_data.xlsx");
  //   } catch (error) {
  //     console.log("get_current_users", error);
  //   }
  // };
  



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onUserAnalytics = (index) => {
     setOpenUser(true)
    setCurrentUsers(index.row);
  };
  const onHandleEdit = (index) => {
    setType("Update User");
    setCurrentUsers(index.row);
    setOpen(true);
  };
  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      width: 100,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "sgid",
      headerName: "SG Id",
      width: 200,
      editable: false,
    },

    {
      field: "first_name",
      headerName: "First Name",
      width: 220,
      editable: false,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 220,
      editable: false,
    },
    {
      field: "name",
      headerName: "Zone",
      width: 150,
      editable: false,
    },

    {
      field: "role",
      headerName: "Role",
      width: 150,
      editable: false,
    },

    {
      field: "Action",
      headerName: "Actions",
      sortable: false,
      width: 150,
      disableClickEventBubbling: false,
      renderCell: (index) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Tooltip title="View">
              <RemoveRedEyeIcon
                id={index}
                style={{
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onUserAnalytics(index);
                }}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Edit
                id={index}
                style={{ marginRight: "15px", cursor: "pointer" }}
                onClick={() => {
                  onHandleEdit(index);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon
                onClick={() => handleDeleteRow(index)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        );
      },

      hide: roleID["role_id"] !== 3 ? false : true,
    },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const getUserDetails = () => {
    let url = ApiURL + "get_users_list";
    axios
      .get(url)
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        setTableData([]);
        console.log("get_current_users", error);
      });
  };

  const rowDelete = (index) => {
    const userDetails = {
      userID: index["row"].id,
    };
    let url = ApiURL + "delete_users";
    axios
      .post(url, { userDetails })
      .then((response) => {
        if (response.data.affected > 0) {
          setShow(true);
          setDeleteModule(false);
          getUserDetails();
          handleClose();
          setType("Delete User");
        }
      })
      .catch((error) => {
        // setTableData([]);
        console.log("get_current_users", error);
      });
    // configParam
    //   .RUN_GQL_API(gqlQueries.DEL_USER, { id: index["row"].id })
    //   .then((data) => {
    //     if (data.update_chryso_users
    //       .affected_rows > 0) {
    //       setShow(true);
    //       getUserDetails();
    //       handleClose();
    //       setType("Delete User");
    //     }
    //   });
  };

  const handleDeleteRow = (index) => {
    setDeleteModule(true);
    setRowIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
    getUserDetails();
    setDeleteModule(false);
    setOpenUser(false);
  };
  const addNew = () => {
    setType("Add User");
    setCurrentUsers([]);
    setOpen(true);
  };

  const setStatusValue = (value) => {
    setStatus(value);
  };

  const onHandleClosed = () => {
    setShow(false);
    setDeleteModule(false);
  };

  const onHandleAlert = () => {
    setShow(true);
  };

  return (
    <div>
      <SuccessPopup
        open={show}
        onClose={onHandleClosed}
        msg={
          type === "Add User"
            ? "User Added Successfully"
            : type === "Update User"
            ? "User Updated Successfully"
            : "User Deleted Successfully"
        }
      />
      <DeletePopup
        open={deleteModule}
        onClose={onHandleClosed}
        handleDelete={() => rowDelete(rowIndex)}
      />
      <UserForm
        open={open}
        handleClose={handleClose}
        type={type}
        data={currentUsers}
        setStatus={setStatusValue}
        onHandleAlert={onHandleAlert}
      />
      <NewUserAnalytics
        open={openUser}
        handleClose={handleClose}
        data={currentUsers}
        setStatus={setStatusValue}
        onHandleAlert={onHandleAlert}
      />
      <Grid container direction="column">
      <Grid item style={{}}>
      {roleID["role_id"] !== 3 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>

            <Button
            variant="contained"
            style={{
              marginBottom: "10px",
              width: matches ? "100%" : null,
              backgroundColor: "#1976d2",
              color: "#fff",
            }}
            onClick={exportToAllExcel}
          >
            <DownloadOutlinedIcon marginRight={3} /> User Performance Analytics
          </Button>
          {/* <Button
            variant="contained"
            style={{
              marginBottom: "10px",
              width: matches ? "100%" : null,
              backgroundColor: "#1976d2",
              color: "#fff",
            }}
            onClick={exportToExcel}
          >
            <DownloadOutlinedIcon marginRight={3} /> Export All User Trials
          </Button> */}
          <Button
            variant="contained"
            style={{
              marginBottom: "10px",
              width: matches ? "100%" : null,
              backgroundColor: "#FFC400",
              color: "#1A1B1F",
            }}
            onClick={addNew}
          >
            Add New User
          </Button>
        </div>
      ) : null}
</Grid>

        <Grid item>
          <Box sx={{ height: 550, width: "100%" }}>
            <DataGrid
              rows={tabledata && tabledata.length > 0 ? tabledata : []}
              columns={columns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RightContainer;
