import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Box, FormControl, MenuItem, Typography } from "@mui/material";
import Select from "../input/SelectViews";
import axios from "axios";
import { styled } from "@mui/system";
import configParam from "../Config";

const Root = styled("div")({
  "@media print": {
    "& .noPrint": {
      display: "none !important",
    },
    "& .printChart": {
      width: "100% !important",
      display: "block !important",
    },
    "& .printView": {
      width: "100% !important",
    },
  },
});

const UserAnalyticsChart = ({ statusComment, props, year, onYearChange }) => {
  const ApiURL = configParam.API_URL;
  // const [year, setYear] = useState("2024");
  const data = ["2023", "2024"];
  const [statusCount, setStatusCount] = useState([]);
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiURL}get_user_info/`, {
          params: {
            fromDate: year ? `${year}-01-01` : "",
            toDate: year ? `${year}-12-31` : "",
            user_id: props.data.id,
          },
        });

        if (response.data.length > 0) {
          const monthlyStatusCounts = monthNames.reduce((acc, month, index) => {
            const monthData = response.data.find(
              (item) => item.period === `${year}-${String(index + 1).padStart(2, '0')}`
            );

            acc.push({
              month,
              Total: monthData ? parseInt(monthData.trials_count, 10) : 0,
              Approved: monthData ? parseInt(monthData.approved_count, 10) : 0,
              Resubmitted: monthData ? parseInt(monthData.resubmit_count, 10) : 0,
              Rejected: monthData ? parseInt(monthData.rejected_count, 10) : 0,
              Pending: monthData ? parseInt(monthData.pending_count, 10) : 0,
              Success: monthData ? parseInt(monthData.success_count, 10) : 0,
              Fail: monthData ? parseInt(monthData.fail_count, 10) : 0,
            });
            return acc;
          }, []);
          setStatusCount(monthlyStatusCounts);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [year, props.data.id]);

  const barChart = {
    series: [
      { name: "Total", data: statusCount.map((item) => item.Total || 0) },
      {
        name: "Pending",
        data: statusCount.map((item) => item.Pending || 0),
      },
      { name: "Rejected", data: statusCount.map((item) => item.Rejected || 0) },
      {
        name: "Resubmitted",
        data: statusCount.map((item) => item.Resubmitted || 0),
      },
      { name: "Approved", data: statusCount.map((item) => item.Approved || 0) },
      {
        name: "Success",
        data: statusCount.map((item) => item.Success || 0),
      },
      {
        name: "Fail",
        data: statusCount.map((item) => item.Fail || 0),
      },
    ],
    chart: {
      height: 300,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
      },
    },
    xaxis: {
      categories: monthNames,
    },
    colors: ["#009BFF", "#F7941D", "#E4003A", "#FEC401", "#229C5B", "#1B5B38","#EA4335"],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [
        "Total Trials",
        "Pending Trials",
        "Rejected Trials",
        "Resubmitted Trials", 
        "Approved Trials", 
        "Successful Trials", 
        "Failed Trials"
      ],
      markers: {
        shape: "circle",
        fillColors: ["#009BFF", "#F7941D", "#E4003A", "#FEC401", "#229C5B", "#1B5B38","#EA4335"],
      },
    },
  };

  const handleChange = (event) => {
    const newYear = event.target.value;
    onYearChange(newYear); // Notify parent component of the year change
  };

  return (
    <Root>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          position: "relative"
        }}
        className="printChart"
      >
        <FormControl sx={{ width: 150, position: "absolute", right: 0, top: 0 }}>
          <Select value={year} onChange={handleChange} options={data} />
        </FormControl>
        <Box
          sx={{
            width: {
              md: "65%",
              xs: "100%",
            },
            textAlign: "right",
            paddingRight: 2,
          }}
          className="printChart"
        >
          <Chart
            options={barChart}
            series={barChart.series}
            type="bar"
            width="100%"
            height="350px"
          />
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontSize: "14px" }}
          >
            User Analytics for {year}
          </Typography>
        </Box>
      </Box>
    </Root>
  );
};

export default UserAnalyticsChart;
