import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import {
  Box,
  FormControl,
  MenuItem,
  Typography,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import configParam from "../../Config";
import Select from "../../input/SelectViews";
import SelectItem from "../../input/SelectItem";
import { useReactToPrint } from "react-to-print";

const Root = styled("div")({
  "@media print": {
    "& .noPrint": {
      display: "none !important",
    },
    "& .printChart": {
      width: "100% !important",
      display: "block !important",
    },
    "& .printView": {
      width: "100% !important",
    },
  },
});
const containerStyle = {
  textAlign: "center",
  padding: "0 8px",
};

const trialHeadingStyle = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#000000",
  padding: "0 8px",
};

const textStyle = {
  color: "#616161",
  fontSize: "14px",
  fontFamily: "DM Sans, sans-serif",
};

const textValueStyle = {
  fontWeight: 800,
  fontSize: "18px",
  fontFamily: "DM Sans, sans-serif",
};
const totalTextValueStyle = {
  fontWeight: 800,
  fontSize: "18px",
  fontFamily: "DM Sans, sans-serif",
  color: "#0086DC",
};

const ZoneAnalytics = () => {
  const ApiURL = configParam.API_URL;
  const [year, setYear] = useState("2024");
  const data = ["2023", "2024"];
  const [statusCount, setStatusCount] = useState([]);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [zone, setZone] = useState([]);
  const [zoneId, setZoneId] = useState(1);
  const [showPdf, setShowPdf] = useState(false);

  const [Q1RMCData, setQ1RMCData] = useState([]);
  const [Q2RMCData, setQ2RMCData] = useState([]);
  const [Q3RMCData, setQ3RMCData] = useState([]);
  const [Q4RMCData, setQ4RMCData] = useState([]);

  const [Q1JobSiteData, setQ1JobSiteData] = useState([]);
  const [Q2JobSiteData, setQ2JobSiteData] = useState([]);
  const [Q3JobSiteData, setQ3JobSiteData] = useState([]);
  const [Q4JobSiteData, setQ4JobSiteData] = useState([]);

  // State for RMC success counts
  const [Q1RMCSuccessCount, setQ1RMCSuccessCount] = useState(0);
  const [Q2RMCSuccessCount, setQ2RMCSuccessCount] = useState(0);
  const [Q3RMCSuccessCount, setQ3RMCSuccessCount] = useState(0);
  const [Q4RMCSuccessCount, setQ4RMCSuccessCount] = useState(0);

  // State for JobSite success counts
  const [Q1JobSiteSuccessCount, setQ1JobSiteSuccessCount] = useState(0);
  const [Q2JobSiteSuccessCount, setQ2JobSiteSuccessCount] = useState(0);
  const [Q3JobSiteSuccessCount, setQ3JobSiteSuccessCount] = useState(0);
  const [Q4JobSiteSuccessCount, setQ4JobSiteSuccessCount] = useState(0);

  const zoneIdRef = useRef();

  useEffect(() => {
    let Zoneurl = ApiURL + "zone_list";
    axios.get(Zoneurl).then((res) => {
      if (res.data.length > 0) {
        console.log("zone", res.data);
        setZone(res.data);
      } else {
        setZone([]);
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiURL}get_zone_quarter_data/`, {
          params: {
            year: year ? `${year}` : "",
            zoneId: zoneId,
          },
        });

        if (response.data.length > 0) {
          console.log("ResponseData", response.data);

          // Filter data by quarter
          const q1Data = response.data.filter((item) =>
            item.quarter.includes("Q1")
          );
          const q2Data = response.data.filter((item) =>
            item.quarter.includes("Q2")
          );
          const q3Data = response.data.filter((item) =>
            item.quarter.includes("Q3")
          );
          const q4Data = response.data.filter((item) =>
            item.quarter.includes("Q4")
          );

          // Filter data by project type 'RMC' and 'JobSite' for each quarter
          const q1RMCData = q1Data.filter((item) =>
            item.project_type.includes("RMC")
          );
          console.log("q1RMCDATA", Q1RMCData);
          setQ1RMCData(q1RMCData);

          const q1RMCSuccessCount = q1RMCData.reduce((total, item) => {
            return total + (parseInt(item.success_count) === 1 ? 1 : 0);
          }, 0);
          setQ1RMCSuccessCount(q1RMCSuccessCount.length);

          const q1JobSiteData = q1Data.filter(
            (item) => !item.project_type.includes("RMC")
          );
          setQ1JobSiteData(q1JobSiteData);

          const q1JobSiteSuccessCount = q1JobSiteData.reduce((total, item) => {
            return total + (parseInt(item.success_count) === 1 ? 1 : 0);
          }, 0);
          setQ1JobSiteSuccessCount(q1JobSiteSuccessCount);

          const q2RMCData = q2Data.filter((item) =>
            item.project_type.includes("RMC")
          );
          setQ2RMCData(q2RMCData);
          const q2RMCSuccessCount = q2RMCData.reduce((total, item) => {
            return total + (parseInt(item.success_count) === 1 ? 1 : 0);
          }, 0);
          setQ2RMCSuccessCount(q2RMCSuccessCount.length);

          const q2JobSiteData = q2Data.filter(
            (item) => !item.project_type.includes("RMC")
          );
          setQ2JobSiteData(q2JobSiteData);
          const q2JobSiteCount = q2JobSiteData.reduce((total, item) => {
            return total + (parseInt(item.success_count) === 1 ? 1 : 0);
          }, 0);
          setQ2JobSiteSuccessCount(q2JobSiteCount);

          const q3RMCData = q3Data.filter((item) =>
            item.project_type.includes("RMC")
          );
          setQ3RMCData(q3RMCData);
          const q3RMCSuccessCount = q3RMCData.reduce((total, item) => {
            return total + (parseInt(item.success_count) === 1 ? 1 : 0);
          }, 0);
          setQ3RMCSuccessCount(q3RMCSuccessCount.length);

          const q3JobSiteData = q3Data.filter(
            (item) => !item.project_type.includes("RMC")
          );
          setQ3JobSiteData(q3JobSiteData);
          const q3JobSiteDataSuccessCount = q3JobSiteData.reduce(
            (total, item) => {
              return total + (parseInt(item.success_count) === 1 ? 1 : 0);
            },
            0
          );
          setQ3RMCSuccessCount(q3JobSiteDataSuccessCount.length);

          const q4RMCData = q4Data.filter((item) =>
            item.project_type.includes("RMC")
          );
          setQ4RMCData(q4RMCData);
          const q4RMCSuccessCount = q4RMCData.reduce((total, item) => {
            return total + (parseInt(item.success_count) === 1 ? 1 : 0);
          }, 0);
          setQ4RMCSuccessCount(q4RMCSuccessCount.length);

          const q4JobSiteData = q4Data.filter(
            (item) => !item.project_type.includes("RMC")
          );
          setQ4JobSiteData(q4JobSiteData);
          const q4JobsiteSuccessCount = q4JobSiteData.reduce((total, item) => {
            return total + (parseInt(item.success_count) === 1 ? 1 : 0);
          }, 0);
          setQ4RMCSuccessCount(q4JobsiteSuccessCount.length);

          // Log data for debugging
          console.log("q1Data", q1Data);
          console.log("q2Data", q2Data);
          console.log("q3Data", q3Data);
          console.log("q4Data", q4Data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [year, zoneId]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setShowPdf(false),
  });

  const componentRef = useRef();
  const contentToPrint = useRef(null);

  const pdftSection = () => {
    setShowPdf(true);
    setTimeout(() => {
      handlePrint();
    }, 100);
  };

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    xaxis: {
      categories: [
        "Number of Trial",
        "Number of Successful Trials",
        "Number of Trial",
        "Number of Successful Trials",
        "Number of Trial",
        "Number of Successful Trials",
        "Number of Trial",
        "Number of Successful Trials",
      ],
    },
    colors: ["#757575", "#EC6608"],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "center", // Position of data labels, if any
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["RMX", "JOB SITE"],
      markers: {
        shape: "circle",
        fillColors: ["#757575", "#EC6608"], // Colors for legend
      },
    },
  };

  const chartSeries = [
    {
      name: "RMX",
      data: [
        Q1RMCData.length,
        Q1RMCSuccessCount,
        Q2RMCData.length,
        Q2RMCSuccessCount,
        Q3RMCData.length,
        Q3RMCSuccessCount,
        Q4RMCData.length,
        Q4RMCSuccessCount,
      ],
    },
    {
      name: "JOB SITE",
      data: [
        Q1JobSiteData.length,
        Q1JobSiteSuccessCount,
        Q2JobSiteData.length,
        Q2JobSiteSuccessCount,
        Q3JobSiteData.length,
        Q3JobSiteSuccessCount,
        Q4JobSiteData.length,
        Q4JobSiteSuccessCount,
      ],
    },
  ];

  // const chartSeries = [
  //   {
  //     name: 'RMX',
  //     data: [
  //       Q1RMCData.length, Q1RMCSuccessCount,
  //       Q2RMCData.length, Q2RMCSuccessCount,
  //       Q3RMCData.length, Q3RMCSuccessCount,
  //       Q4RMCData.length, Q4RMCSuccessCount
  //     ],
  //   },
  //   {
  //     name: 'JOB SITE',
  //     data: [
  //       Q1JobSiteData.length, Q1JobSiteSuccessCount,
  //       Q2JobSiteData.length, Q2JobSiteSuccessCount,
  //       Q3JobSiteData.length, Q3JobSiteSuccessCount,
  //       Q4JobSiteData.length, Q4JobSiteSuccessCount
  //     ],
  //   },
  // ];

  const onChangeValue = (event) => {
    console.log("evebtttt", event.target.value);
    setZoneId(event.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const getZoneName = (id) => {
    const selectedZone = zone.find((z) => z.id === id);
    return selectedZone ? selectedZone.name.toUpperCase() : "EAST";
  };
  return (
    <Root>
      <div style={{ padding: 20 }} ref={printRef}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            style={{
              marginBottom: "10px",
              backgroundColor: "#FFC400",
              color: "#1A1B1F",
              marginRight: "16px",
            }}
            className="hide-when-printing" // Add this class
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
          >
            Save PDF
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Select
            value={year}
            onChange={handleYearChange}
            options={data}
            style={{ marginRight: 20 }} // Space between the two selects
          />

          <SelectItem
            label="Select Zone"
            name="zoneId"
            value={zoneId}
            inputRef={zoneIdRef}
            datakey="id"
            dataValue="name"
            onChange={onChangeValue}
            data={zone}
          />
        </div>
        <Typography
          style={{
            textAlign: "center",
            color: "#272D36",
            fontWeight: 800,
            fontFamily: "DM Sans, sans-serif",
            fontSize: "18px",
          }}
        >
          {getZoneName(zoneId)} ZONE - {year}
        </Typography>

        <Typography
          style={{
            marginTop: 30,
            marginBottom: 20,
            color: "#1B1F26B8",
            fontWeight: 800,
            fontFamily: "DM Sans, sans-serif",
            fontSize: "18px",
          }}
        >
          Percentage(%) of Successful Trials
        </Typography>
        <Root>
          <Grid
            container
            style={{
              borderRadius: "8px", // Adjust the radius as needed
              padding: "16px", // Optional: to add some space inside the container
            }}
          >
            {/* First Grid Item */}
            <Grid
              item
              xs={12 / 5} // 1/5 of the space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {/* Content inside this Grid item */}
              <Grid container direction="column">
                {/* Number of Trials Title */}
                <Grid item>
                  <Typography style={trialHeadingStyle}>Quarter 1</Typography>
                </Grid>
                {/* Horizontal Layout for RMX, Job Site, and Total */}
                <Grid item style={{ marginTop: "10px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>RMX</Typography>
                      <Typography style={textValueStyle}>
                        {Q1RMCSuccessCount || 0}
                      </Typography>
                    </Grid>
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>JOB SITE</Typography>
                      <Typography style={textValueStyle}>
                        {Q1JobSiteSuccessCount || 0}
                      </Typography>
                    </Grid>
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>TOTAL</Typography>
                      <Typography style={totalTextValueStyle}>
                        {(Q1RMCSuccessCount || 0) +
                          (Q1JobSiteSuccessCount || 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Vertical Divider */}
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  position: "absolute",
                  right: 0,
                  height: "100%",
                }}
              />
            </Grid>

            {/* Second Grid Item */}
            <Grid
              item
              xs={12 / 5} // 1/5 of the space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {/* Content inside this Grid item */}
              <Grid container direction="column">
                <Grid item>
                  <Typography style={trialHeadingStyle}>Quarter 2</Typography>
                </Grid>
                {/* Placeholder for additional data */}
                <Grid item style={{ marginTop: "10px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>RMX</Typography>
                      <Typography style={textValueStyle}>
                        {Q2RMCSuccessCount || 0}
                      </Typography>
                    </Grid>
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>JOB SITE</Typography>
                      <Typography style={textValueStyle}>
                        {Q2JobSiteSuccessCount || 0}
                      </Typography>
                    </Grid>
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>TOTAL</Typography>
                      <Typography style={totalTextValueStyle}>
                        {(Q2RMCSuccessCount || 0) +
                          (Q2JobSiteSuccessCount || 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                style={{
                  position: "absolute",
                  right: 0,
                  height: "100%",
                }}
              />
            </Grid>

            {/* Third Grid Item */}
            <Grid
              item
              xs={12 / 5} // 1/5 of the space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {/* Content inside this Grid item */}
              <Grid container direction="column">
                <Grid item>
                  <Typography style={trialHeadingStyle}>Quarter 3</Typography>
                </Grid>
                {/* Placeholder for additional data */}
                <Grid item style={{ marginTop: "10px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>RMX</Typography>
                      <Typography style={textValueStyle}>
                        {Q3RMCSuccessCount || 0}
                      </Typography>
                    </Grid>
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>JOB SITE</Typography>
                      <Typography style={textValueStyle}>
                        {Q3JobSiteSuccessCount || 0}
                      </Typography>
                    </Grid>
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>TOTAL</Typography>
                      <Typography style={totalTextValueStyle}>
                        {(Q3RMCSuccessCount || 0) +
                          (Q3JobSiteSuccessCount || 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                style={{
                  position: "absolute",
                  right: 0,
                  height: "100%",
                }}
              />
            </Grid>

            {/* Fourth Grid Item */}
            <Grid
              item
              xs={12 / 5} // 1/5 of the space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {/* Content inside this Grid item */}
              <Grid container direction="column">
                <Grid item>
                  <Typography style={trialHeadingStyle}>Quarter 4</Typography>
                </Grid>
                {/* Placeholder for additional data */}
                <Grid item style={{ marginTop: "10px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>RMX</Typography>
                      <Typography style={textValueStyle}>
                        {Q4RMCSuccessCount || 0}
                      </Typography>
                    </Grid>
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>JOB SITE</Typography>
                      <Typography style={textValueStyle}>
                        {Q4JobSiteSuccessCount || 0}
                      </Typography>
                    </Grid>
                    <Grid item style={containerStyle}>
                      <Typography style={textStyle}>TOTAL</Typography>
                      <Typography style={totalTextValueStyle}>
                        {(Q4RMCSuccessCount || 0) +
                          (Q4JobSiteSuccessCount || 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Root>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
          }}
          className="printChart"
        >
          {/* <FormControl sx={{ width: 150, position: "absolute", right: 0, top: 0 }}>
        <Select value={year} onChange={handleChange} options={data} />
      </FormControl> */}
          <Box
            sx={{
              width: {
                md: "80%",
                xs: "100%",
              },
              textAlign: "right",
              paddingRight: 3,
            }}
            className="printChart"
          >
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={350}
            />
          </Box>
        </Box>
      </div>
    </Root>
  );
};

export default ZoneAnalytics;
