import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  CardActions,
  Collapse,
  FormControl,
  colors,
} from "@mui/material";
import configParam from "../../Config";
import SearchTable from "./SearchTable";
import VisitTable from "./VisitTable";
import {
  menuName,
  graphSeriesData,
  projectType,
  enquiryNumberList,
  typeofConcrete,
  customerList,
  projectDetailList,
  congreteGrade,
  cementBrand,
  techPerson,
  filterDataArray,
  filterExcelData,
  filterInputSearchList,
} from "../../recoil/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import Chart from "react-apexcharts";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import axios, { all } from "axios";
import Box from "@mui/material/Box";
import Select from "../../input/SelectViews";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DataGrid } from "@mui/x-data-grid";
import {
  Line,
  Bar,
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";
import DownloadIcon from "@mui/icons-material/Download";
import PreviewPdf from "./PreviewPdf";
import { useReactToPrint } from "react-to-print";
import TableViewIcon from "@mui/icons-material/TableView";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import { forEach } from "lodash";
import { styled } from "@mui/system";
import Logo from "../../asset/Logo.png";
import ZoneAnalytics from "./ZoneAnalytics";

const Root = styled("div")({
  "@media print": {
    "& .printHeader": {
      display: "flex !important",
    },
    "& .noPrint": {
      display: "none !important",
    },
    "& .printChart": {
      height: "auto !important",
      overflow: "visible !important",
    },
    "& .printPadd": {
      paddingLeft: "10px",
    },
  },
});

// import Tooltip from '@mui/material/Tooltip';
const CardComponent = () => {
  const [isLoading] = useState(false);
  const [zoneTotal, setZoneTotal] = useState([]);
  const [, setChartValue] = useState({});
  const [views] = useState("2024");
  const ApiURL = configParam.API_URL;
  useEffect(() => {
    // getZoneList();
    // getZoneCount();
    let toDate = moment(new Date());
    getZoneWiseTrialCount();
    getChartValue(views);
  }, []);

  const getChartValue = (views) => {
    let url = ApiURL + "getChartData";
    axios
      .get(url, {
        params: {
          fromDate: `${views}-01-01`,
          toDate: `${views}-12-31`,
        },
      })
      .then((res) => {
        if (Object.keys(res.data).length > 0) {
          setChartValue(res.data);
        } else {
          setChartValue({});
        }
      });
  };

  const getZoneWiseTrialCount = () => {
    let url = ApiURL + "getCount";
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setZoneTotal(res.data);
      } else {
        setZoneTotal([]);
      }
    });
  };

  const getCard = () => {
    const card = [];
    let totalTrials = 0;
    const sortedData = zoneTotal.sort((a, b) => {
      // Check if either 'a' or 'b' has the name "NA"
      if (a.name === "NA" && b.name !== "NA") {
        return 1; // "NA" should come after 'b'
      } else if (a.name !== "NA" && b.name === "NA") {
        return -1; // 'a' should come before "NA"
      } else {
        // For other cases, maintain the original order
        return 0;
      }
    });

    if (sortedData.length > 0) {
      sortedData.map((data) => {
        totalTrials = totalTrials + Number(data.totalcount);
        // Object.keys(zoneList).forEach(function (key, index) {
        card.push(
          <Grid item xs={6} lg md={6} xl>
            <Card
              variant="outlined"
              style={{
                backgroundColor: "#FCC900",
                border: "1px solid #FCC900",
                marginLeft: 4,
                borderRadius: "15px",
              }}
            >
              <CardContent>
                <Typography
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                  gutterBottom
                >
                  {data.name === "NA" ? "All Regions" : data.name}
                </Typography>
                <Grid
                  container
                  style={{ marginTop: 15, alignItems: "end" }}
                  rowSpacing={1}
                >
                  <Grid item xs={6}>
                    <TrendingUpIcon
                      style={{
                        verticalAlign: "middle",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                      gutterBottom
                    >
                      {data.name === "NA" ? totalTrials : data.totalcount}
                    </Typography>
                    <Typography style={{ fontSize: "12px" }} gutterBottom>
                      {" "}
                      Total Trials
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      });
    }
    return card;
  };

  return (
    <div style={{ width: "100%", display: "flex", padding: "15px" }}>
      {isLoading ? (
        <div className="skeleton-dashboard" style={{ width: "100%" }}>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Skeleton
              style={{ width: "19%", height: 135, transform: "none" }}
            />
            <Skeleton
              style={{ width: "19%", height: 135, transform: "none" }}
            />
            <Skeleton
              style={{ width: "19%", height: 135, transform: "none" }}
            />
            <Skeleton
              style={{ width: "19%", height: 135, transform: "none" }}
            />
            <Skeleton
              style={{ width: "19%", height: 135, transform: "none" }}
            />
          </Grid>
          <Grid
            container
            style={{ justifyContent: "space-between", marginTop: "20px" }}
          >
            <Skeleton
              style={{ width: "65%", height: 400, transform: "none" }}
            />
            <Skeleton
              style={{ width: "34%", height: 400, transform: "none" }}
            />
          </Grid>
          <Grid
            container
            style={{ justifyContent: "space-between", marginTop: "20px" }}
          >
            <Skeleton
              style={{ width: "100%", height: 400, transform: "none" }}
            />
          </Grid>
        </div>
      ) : (
        <Grid container spacing={1}>
          <Grid
            container
            spacing={1}
            md={12}
            xs={12}
            sm={12}
            style={{ marginTop: "20px" }}
          >
            {getCard()}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const AdvancedSearchTable = ({ isTabDisabled }) => {
  const matches = useMediaQuery("(max-width:600px)");
  const [, setMenu] = useRecoilState(menuName);
  const [seriesData] = useRecoilState(graphSeriesData);
  const [projectTypeList, setProjectTypeList] = useRecoilState(projectType);
  const [typeConcrete, setTypeofConcrete] = useRecoilState(typeofConcrete);
  const [customer, setCustomerList] = useRecoilState(customerList);
  const [projectDetails, setProjectDetailList] =
    useRecoilState(projectDetailList);
  const [enquirNumList, setEnquiryNumList] = useRecoilState(enquiryNumberList);
  const [congrete, setCongreteGrade] = useRecoilState(congreteGrade);
  const [cementBrands, setCementBrand] = useRecoilState(cementBrand);
  const [techPer, setTechPerson] = useRecoilState(techPerson);
  const [, setNorthValue] = useState([]);
  const [, setEastValue] = useState([]);
  const [, setWestValue] = useState([]);
  const [, setSouthValue] = useState([]);
  const [, setNaValue] = useState([]);
  const [show, setShow] = useState(false);
  const [chartValue, setChartValue] = useState({});
  const [views, setViews] = useState("2024");
  const [activeButton, setActiveButton] = useState("table");
  const ApiURL = configParam.API_URL;
  const [refreshFilterData, setRefreshFilterData] = useState(false);
  const [searchValues, setSearchValues] = useRecoilState(filterInputSearchList);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const filterArrayDatas = useRecoilValue(filterExcelData);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [printData, setPrintData] = useState([]);
  const userDet = localStorage.getItem("user");
  let roleID = JSON.parse(userDet);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${ApiURL}allReginalAnalyticsChart/`,
          {}
        );
        if (response.data) {
          const setPrint = response.data;
          console.log("setPrintData", setPrint);
          setPrintData(setPrint);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    console.log("gxegxe", printData);
    console.log("deeeee", printData.length);
  }, [printData]);

  const countMonths = () => {
    const monthCounts = Array.from({ length: 12 }, () => 0);
    filterArrayDatas.forEach((item) => {
      const year = new Date(item.created_at).getFullYear();
      if (year.toString() === selectedYear.toString()) {
        const month = new Date(item.created_at).getMonth();
        monthCounts[month]++;
      }
    });
    return monthCounts;
  };

  const handleFilterYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const exportToExcel = async () => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Function to style the header row
    const styleHeaderRow = (worksheet) => {
      worksheet.getRow(1).font = { bold: true };
      worksheet.getRow(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getRow(1).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFE0B2" }, // light orange background
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    };

    // Function to add data to a worksheet
    const addDataToSheet = (worksheet, data) => {
      worksheet.columns = [
        { header: "Zone Name", key: "zone_name", width: 15 },
        { header: "Total Trials", key: "trials_count", width: 20 },
        { header: "Approved Trials", key: "approved_count", width: 20 },
        { header: "Pending Trials", key: "pending_count", width: 20 },
        { header: "Rejected Trials", key: "rejected_count", width: 20 },
        { header: "Resubmit Trials", key: "resubmit_count", width: 20 },
        { header: "Success Trials", key: "success_count", width: 20 },
        { header: "Failed Trials", key: "fail_count", width: 20 },
      ];

      styleHeaderRow(worksheet);

      data.forEach((item) => {
        const row = worksheet.addRow(item);

        // Apply left alignment to all cells in the row
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = { horizontal: "left", vertical: "middle" };
        });
      });
    };

    // Function to ensure all zones are present
    const ensureZones = (data, zones) => {
      const defaultZoneData = {
        trials_count: 0,
        approved_count: 0,
        pending_count: 0,
        rejected_count: 0,
        resubmit_count: 0,
        success_count: 0,
        fail_count: 0,
      };

      return zones.map((zone) => {
        const zoneData = data.find((item) => item.zone_name === zone);
        return zoneData ? zoneData : { zone_name: zone, ...defaultZoneData };
      });
    };

    // Fetch data and add rows
    try {
      const url = ApiURL + "all_zone_trial_info";
      const response = await axios.get(url);
      const allData = response.data;

      // Define the zones to check
      const zones = ["East", "West", "North", "South", "NA"];

      // Separate overall data and month-wise data
      const overallData = allData.filter((item) => item.period === "Overall");
      const monthWiseData = allData.filter((item) => item.period !== "Overall");

      // Ensure all zones are present in the overall data
      const overallDataWithZones = ensureZones(overallData, zones);

      // Add overall data to a separate sheet
      const overallSheet = workbook.addWorksheet("Overall Trials");
      addDataToSheet(overallSheet, overallDataWithZones);

      // Sort months in ascending order (e.g., "2023-04", "2023-05", ...)
      const months = [
        ...new Set(monthWiseData.map((item) => item.period)),
      ].sort();

      // Add month-wise data to separate sheets, ensuring all zones are represented
      months.forEach((month) => {
        const monthData = monthWiseData.filter((item) => item.period === month);
        const monthDataWithZones = ensureZones(monthData, zones);
        const monthSheet = workbook.addWorksheet(month);
        addDataToSheet(monthSheet, monthDataWithZones);
      });

      // Create buffer
      const buffer = await workbook.xlsx.writeBuffer();

      // Create a blob from the buffer and save it using file-saver
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "all_zone_trial_info.xlsx");
    } catch (error) {
      console.log("get_current_users", error);
    }
  };

  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data1 = {
    series: [
      {
        name: "Count",
        data: countMonths(),
      },
    ],

    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      xaxis: {
        categories: monthLabels,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  const RefreshFilterData = () => {
    setShow(true);
    setRefreshFilterData(true);
  };
  const data = ["2023", "2024"];

  useEffect(() => {
    setMenu("Dashboard");
    getProjectDetails();
    getTypeofConcreteDetails();
    getCustomerDetails();
    getProjectDetailList();
    get_congrete_Grade();
    get_congrete_Brand();
    get_tech_persion();
    geEnquiryNumDetailList();
  }, []);
  const getProjectDetails = () => {
    if (projectTypeList.length === 0) {
      let url = ApiURL + "get_project_type";
      axios.get(url).then((res) => {
        if (res.data.length > 0) {
          setProjectTypeList(res.data);
        } else {
          setProjectTypeList([]);
        }
      });
    }
  };
  const getCustomerDetails = () => {
    if (customer.length === 0) {
      let url = ApiURL + "get_customer_name";
      axios.get(url).then((res) => {
        if (res.data.length > 0) {
          setCustomerList(res.data);
        } else {
          setCustomerList([]);
        }
      });
    }
  };
  const getTypeofConcreteDetails = () => {
    if (typeConcrete.length === 0) {
      let url = ApiURL + "get_concrete_Type";
      axios.get(url).then((res) => {
        if (res.data.length > 0) {
          setTypeofConcrete(res.data);
        } else {
          setTypeofConcrete([]);
        }
      });
    }
  };
  const getProjectDetailList = () => {
    if (projectDetails.length === 0) {
      let url = ApiURL + "getProjectDetails";
      axios.get(url).then((res) => {
        if (res.data.length > 0) {
          setProjectDetailList(res.data);
        } else {
          setProjectDetailList([]);
        }
      });
    }
  };

  const geEnquiryNumDetailList = () => {
    if (enquirNumList.length === 0) {
      let url = ApiURL + "getEnquiryNumber";
      axios.get(url).then((res) => {
        if (res.data.length > 0) {
          setEnquiryNumList(res.data);
        } else {
          setEnquiryNumList([]);
        }
      });
    }
  };

  const get_congrete_Grade = () => {
    if (congrete.length === 0) {
      let url = ApiURL + "get_congrete_Grade";
      axios.get(url).then((res) => {
        if (res.data.length > 0) {
          setCongreteGrade(res.data);
        } else {
          setCongreteGrade([]);
        }
      });
    }
  };
  const get_tech_persion = () => {
    if (techPer.length === 0) {
      let url = ApiURL + "get_tech_person";
      axios.get(url).then((res) => {
        if (res.data.length > 0) {
          setTechPerson(res.data);
        } else {
          setTechPerson([]);
        }
      });
    }
  };
  const get_congrete_Brand = () => {
    if (cementBrands.length === 0) {
      let url = ApiURL + "get_chryso_cement_brand";
      axios.get(url).then((res) => {
        if (res.data.length > 0) {
          setCementBrand(res.data);
        } else {
          setCementBrand([]);
        }
      });
    }
  };

  // const getmonthList = configParam.GET_MONTH_LIST;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var tempMonthdata = [];
  monthNames.map((val, index) => {
    tempMonthdata.push({
      month: index,
      monthName: val,
      East: [],
      West: [],
      North: [],
      South: [],
    });
  });

  const handleyearChange = (e) => {
    setViews(e.target.value);
  };
  useEffect(() => {
    let toDate = moment(new Date());
    getChartValue(views);
  }, [views]);

  const getChartValue = (views) => {
    let url = ApiURL + "getChartData";
    axios
      .get(url, {
        params: {
          fromDate: `${views}-01-01`,
          toDate: `${views}-12-31`,
        },
      })
      .then((res) => {
        if (Object.keys(res.data).length > 0) {
          setChartValue(res.data);
        } else {
          setChartValue({});
        }
      });
  };

  useEffect(() => {
    let east = filterZoneData(0, "East");
    setEastValue(east);
    let north = filterZoneData(2, "North");
    setNorthValue(north);
    let west = filterZoneData(1, "West");
    setWestValue(west);
    let south = filterZoneData(3, "South");
    setSouthValue(south);
    let na = filterZoneData(4, "NA");
    setNaValue(na);
  }, [seriesData]);

  const filterZoneData = (index, zone) => {
    let keys = Object.keys(seriesData);
    let value = keys.map((val) => {
      return seriesData[val][index][zone];
    });
    return value;
  };

  const hideElement = () => {
    setShow(!show);
    setRefreshFilterData(false);
    const myElement = document.getElementById("my-element");
    if (show === true) {
      myElement.style.overflow = "auto";
    } else {
      myElement.style.overflow = "hidden";
    }
  };
  const data2 = {
    series: [
      {
        name: "East",
        data: chartValue.eastValue,
      },
      {
        name: "West",
        data: chartValue.westValue,
      },
      {
        name: "North",
        data: chartValue.narthValue,
      },
      {
        name: "South",
        data: chartValue.southValue,
      },
    ],
    options: {
      chart: {
        height: 500,
        stacked: true,
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "70px",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["0000"],
        },
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        // text: 'Product Trends by Month',
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: chartValue.monthList,
      },
    },
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const handleSearchValue = (value) => {
    if (value === null) {
      setSearchValues("");
    } else {
      setSearchValues(value);
    }
    setShow(true);
  };
  const componentRef = useRef();
  const contentToPrint = useRef(null);
  // React-to-print hook for handling PDF printing
  const handlePrint = useReactToPrint({
    documentTitle: "Region Wise Analytics Report",
    onBeforePrint: () => console.log("Preparing to print..."),
    onAfterPrint: () => console.log("Printing completed."),
    removeAfterPrint: true,
    content: () => componentRef.current,
  });
  const transformDataForChart = (data) => {
    return {
      series: [parseInt(data.success_count, 10), parseInt(data.fail_count, 10)],
      labels: ["Success", "Fail"],
      name: data.name,
    };
  };
  return (
    <div ref={componentRef}>
      <Root>
        <Grid
          container
          style={{
            marginBottom: 0,
            display: "none",
            borderBottom: "1px solid #e7e7e7",
          }}
          className="printHeader"
        >
          <Grid item xs={2}>
            <img
              className="printPadd"
              src={Logo}
              alt="Logo"
              style={{ width: 115 }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: "100%",
            }}
          >
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                fontWeight: "bold",
                maxWidth: "700px",
                fontSize: "24px",
              }}
            >
              REGION WISE TRIAL DATA
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <div
          className="printHeader"
          style={{
            display: "none",
            flexWrap: "wrap",
            padding: "0 20px",
            justifyContent: "space-between",
          }}
        >
          {printData && printData.length > 0 ? (
            printData.map((data, index) => {
              const chartData = transformDataForChart(data);
              if (data.name !== "NA") {
                return (
                  <Box
                    sx={{
                      width: "45%",
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                    key={index}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      {data.name}
                    </p>
                    <Chart
                      options={{
                        chart: {
                          type: "pie",
                        },
                        labels: chartData.labels,
                        legend: {
                          position: "bottom",
                        },
                        dataLabels: {
                          enabled: true,
                          formatter: (value, { seriesIndex }) => {
                            const total = chartData.series.reduce(
                              (acc, value) => acc + value,
                              0
                            );
                            const percentage = (
                              (chartData.series[seriesIndex] / total) *
                              100
                            ).toFixed(1);
                            return `${chartData.series[seriesIndex]} (${percentage}%)`;
                          },
                          style: {
                            fontSize: "14px",
                            fontWeight: "bold",
                          },
                          dropShadow: {
                            enabled: true,
                            top: 1,
                            left: 1,
                            blur: 1,
                            opacity: 0.45,
                          },
                          offsetY: 0,
                        },
                        plotOptions: {
                          pie: {
                            dataLabels: {
                              offset: -20, // Adjust this value to move the labels inside the pie
                              minAngleToShowLabel: 10, // Minimum angle to show a label
                            },
                          },
                        },
                      }}
                      series={chartData.series}
                      type="pie"
                      width="380"
                    />
                    <Box style={{ width: "100%" }}>
                      <Box
                        sx={{
                          borderBottom: "1px solid #e7e7e7",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            margin: "5px 0",
                            padding: "0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              width: "12px",
                              height: "12px",
                              background: "#9778de",
                              borderRadius: "50%",
                              display: "block",
                              marginRight: "10px",
                            }}
                          ></span>
                          Total Counts
                        </p>{" "}
                        <span>{data.trials_count}</span>
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid #e7e7e7",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            margin: "5px 0",
                            padding: "0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              width: "12px",
                              height: "12px",
                              background: "#27ae60",
                              borderRadius: "50%",
                              display: "block",
                              marginRight: "10px",
                            }}
                          ></span>
                          Approved Counts
                        </p>{" "}
                        <span>{data.approved_count}</span>
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid #e7e7e7",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            margin: "5px 0",
                            padding: "0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              width: "12px",
                              height: "12px",
                              background: "#f68f52",
                              borderRadius: "50%",
                              display: "block",
                              marginRight: "10px",
                            }}
                          ></span>
                          Pending Counts
                        </p>{" "}
                        <span>{data.pending_count}</span>
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid #e7e7e7",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            margin: "5px 0",
                            padding: "0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              width: "12px",
                              height: "12px",
                              background: "#375eca",
                              borderRadius: "50%",
                              display: "block",
                              marginRight: "10px",
                            }}
                          ></span>
                          Resubmit Counts
                        </p>{" "}
                        <span>{data.resubmit_count}</span>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            margin: "5px 0",
                            padding: "0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              width: "12px",
                              height: "12px",
                              background: "#f75f5f",
                              borderRadius: "50%",
                              display: "block",
                              marginRight: "10px",
                            }}
                          ></span>
                          Rejected Counts
                        </p>{" "}
                        <span>{data.rejected_count}</span>
                      </Box>
                    </Box>
                  </Box>
                );
              }
            })
          ) : (
            <p>No data available</p>
          )}
        </div>
        <div
          className="noPrint"
          style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
        >
          <button
            style={{
              border: "none",
              cursor: "pointer",
              background: "none",
              padding: 10,
              fontWeight: "400",
              fontSize: "16px",
              color: activeButton === "table" ? "white" : "black",
              backgroundColor: activeButton === "table" ? "gray" : "white",
            }}
            onClick={() => handleButtonClick("table")}
          >
            {isMobile ? (
              <>
                <TableViewIcon />
              </>
            ) : (
              <span>Table View</span>
            )}
          </button>

          <div stlye={{ marginLeft: 20 }}>
            <button
              style={{
                border: "none",
                cursor: "pointer",
                background: "none",
                padding: 10,
                fontWeight: "400",
                fontSize: "16px",
                color: activeButton === "graph" ? "white" : "black",
                backgroundColor: activeButton === "graph" ? "gray" : "white",
              }}
              onClick={() => handleButtonClick("graph")}
            >
              {isMobile ? (
                <div style={{ marginLeft: 20 }}>
                  <EqualizerIcon />
                </div>
              ) : (
                <span>Graphical View</span>
              )}
            </button>
          </div>
          <div style={{marginLeft:20}}>
          <button
  style={{
    border: "none",
    cursor: "pointer",
    background: "none",
    padding: 10,
    fontWeight: "400",
    fontSize: "16px",
    color: activeButton === "zone" ? "white" : "black",
    backgroundColor: activeButton === "zone" ? "gray" : "white",
  }}
  onClick={() => handleButtonClick("zone")}
>
  {isMobile ? (
    <>
      <TableViewIcon /> {/* Replace with your icon component */}
    </>
  ) : (
    <span>Zone Analytics</span>
  )}
</button>
          </div>

          {activeButton !== "graph" && activeButton !== "zone" && (
            <div style={{ marginLeft: "auto" }}>
              {isMobile ? (
                <>
                  <FilterAltIcon onClick={hideElement} />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                  }}
                >
                    {roleID["role_id"] !== 3 ? (
                           <Button
                           variant="contained"
                           style={{
                             marginBottom: "10px",
                             width: matches ? "100%" : null,
                             backgroundColor: "#1976d2",
                             color: "#fff",
                           }}
                           onClick={exportToExcel}
                         >
                           <DownloadOutlinedIcon marginRight={3} /> Export All Zone
                           Trials
                         </Button>
                    ):null}
                  <Button
                    variant="contained"
                    onClick={hideElement}
                    style={{ width: "120px", height: "40px", fontSize: "14px" }}
                  >
                    <span>Filter</span>
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <Grid
          container
          style={{
            marginBottom: 0,
            display: "none",
            borderBottom: "1px solid #e7e7e7",
          }}
          className="printHeader"
        >
          <Grid item xs={2}>
            <img
              className="printPadd"
              src={Logo}
              alt="Logo"
              style={{ width: 115 }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: "100%",
            }}
          >
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                fontWeight: "bold",
                maxWidth: "700px",
                fontSize: "24px",
              }}
            >
              REGION WISE TRIAL DATA
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        {/* Conditional Rendering based on activeButton */}
    {activeButton !== "table" ? (
      <Grid item xl={12} md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
        {/* Render ZoneAnalytics if activeButton is 'zone' */}
        {activeButton === "zone" ? (
          <div>
          <ZoneAnalytics />
          </div>
      
        ) : (
          <>
            <Box
              className="noPrint"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Select
                value={filterArrayDatas.length > 0 ? selectedYear : views}
                onChange={
                  filterArrayDatas.length > 0 ? handleFilterYearChange : handleyearChange
                }
                options={data}
              />
              <Button
                variant="contained"
                style={{
                  marginBottom: "10px",
                  backgroundColor: "#FFC400",
                  color: "#1A1B1F",
                  marginRight: "16px",
                }}
                onClick={() => {
                  handlePrint(null, () => contentToPrint.current);
                }}
              >
                Save PDF
              </Button>
            </Box>
            <Typography
              variant="h2"
              className="printPadd"
              style={{ fontWeight: "600", fontSize: "20px" }}
            >
              Trials Counts
            </Typography>
            <Grid container spacing={1}>
              <Box className="noPrint" sx={{ width: "100%" }}>
                {filterArrayDatas.length > 0 ? (
                  <Chart options={data1.options} series={data1.series} type="bar" height="420px" />
                ) : (
                  <Chart options={data2.options} series={data2.series} type="bar" height="420px" />
                )}
              </Box>
              <Box
                style={{ width: "800px", height: "0", overflow: "hidden" }}
                className="printChart"
              >
                {filterArrayDatas.length > 0 ? (
                  <Chart options={data1.options} series={data1.series} type="bar" height="420px" />
                ) : (
                  <Chart options={data2.options} series={data2.series} type="bar" height="420px" />
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    ) : (
      <Grid item xl={12} md={12} xs={12}>
        <VisitTable
          isTabDisabled={isTabDisabled}
          onHandleFilterSlice={RefreshFilterData}
          onSearch={handleSearchValue}
          searchValue={searchValues}
        />
      </Grid>
    )}
        {show && (
          <Grid item xs={7}>
            <div
              onClick={hideElement}
              style={{
                width: "100%",
                background: "#000",
                position: "fixed",
                right: 0,
                top: 0,
                height: "100%",
                zIndex: 1297,
                opacity: 0.2,
              }}
            ></div>
            <Box
              sx={{
                width: { xs: "100%", sm: "400px" },
                background: "#fff",
                position: "fixed",
                right: 0,
                top: 0,
                height: "100%",
                zIndex: 1299,
                overflow: "auto",
              }}
            >
              <SearchTable
                style={{ zIndex: 1 }}
                onHandleClose={hideElement}
                onHandleFilterSlice={refreshFilterData}
                searchValue={searchValues}
                onSearch={handleSearchValue}
              />
            </Box>
          </Grid>
        )}
      </Root>
    </div>
  );
};

const FilterCalcTable = (props) => {
  const filterAllExcelArrayData = useRecoilValue(filterExcelData);
  const [activeButton, setActiveButton] = useState("table");
  const [showPdf, setShowPdf] = useState(false);
  const userDet = localStorage.getItem("user");
  let roleID = JSON.parse(userDet);
  const roleSection = roleID.role_id !== 3;
  let p20Median = "";
  let p15Median = "";
  let p16Median = "";
  let p18Median = "";
  let p11Median = "";
  let snfMedian = "";
  let ligno50Median = "";
  let lignoMedian = "";
  let gn1Median = "";
  let cfMedian = "";
  let g1Median = "";
  let ac2_ac3Median = "";
  let ac1Median = "";
  let ac5_ac6Median = "";
  let df1Median = "";
  let df2Median = "";
  let df3Median = "";
  let ar1Median = "";
  let ar2Median = "";
  let ar3Median = "";
  let ar4Median = "";
  let ar5Median = "";
  let ar6Median = "";
  let ar7Median = "";
  let cr_1Median = "";
  let b29_p23Median = "";
  let p27_r650Median = "";
  let a15_p24Median = "";
  let pr1Median = "";
  let gn2Median = "";
  let n43Median = "";
  let glyMedian = "";
  let c2Median = "";
  let ac6Median = "";
  let p25_a19Median = "";
  let p26Median = "";
  let vma1Median = "";
  let vma2Median = "";
  let othersMedian = "";

  function filterArrayByConditions(data) {
    const PseriesDataArray = [];
    const snfSeriesDataArray = [];
    data.forEach((obj) => {
      if (!obj["snf_n5"]) {
        PseriesDataArray.push(obj);
      } else if (
        !obj["p11"] &&
        !obj["p15"] &&
        !obj["p18_hr150"] &&
        !obj["p20"] &&
        !obj["b29_p23"] &&
        !obj["a15_p24"] &&
        !obj["p25_a19"] &&
        !obj["p26"] &&
        obj["snf_n5"]
      ) {
        snfSeriesDataArray.push(obj);
      }
    });
    return { PseriesDataArray, snfSeriesDataArray };
  }

  // Usage
  const data = filterAllExcelArrayData.map((x) => x.compositions);
  const { PseriesDataArray, snfSeriesDataArray } =
    filterArrayByConditions(data);

  function calculateStandardDeviation(arr) {
    const filteredArr = arr
      .map((value) => parseFloat(value))
      .filter((value) => !isNaN(value) && value !== 0);
    if (filteredArr.length === 0) {
      return 0;
    }
    const mean =
      filteredArr.reduce((acc, val) => acc + val, 0) / filteredArr.length;
    const squaredDifferences = filteredArr.map((value) =>
      Math.pow(value - mean, 2)
    );
    const meanSquaredDifference =
      squaredDifferences.reduce((acc, val) => acc + val, 0) /
      squaredDifferences.length;
    const standardDeviation = Math.sqrt(meanSquaredDifference);
    const roundedStandardDeviation = parseFloat(standardDeviation.toFixed(2));
    return roundedStandardDeviation;
  }

  // Lower Limit Calc Func
  const lowerLimit = (params1, params2) => {
    const result = Math.max(params1 - params2, 0);
    return typeof result === "number" ? parseFloat(result.toFixed(2)) : result;
  };

  function upperLimit(params1, params2) {
    const num1 = parseFloat(params1);
    const num2 = parseFloat(params2);

    if (isNaN(num1) || isNaN(num2)) {
      return null;
    }

    const results = num1 + num2;
    return typeof results === "number"
      ? parseFloat(results.toFixed(2))
      : results;
  }

  function evenNumber(arr) {
    const filteredArr = arr
      .filter((value) => value !== "" && value !== 0 && value !== undefined)
      .map((value) => {
        if (typeof value === "string") {
          if (value.includes(" ")) {
            value = value.trim();
          }
          value = value.replace("O", "0");
        }
        const num = parseFloat(value);
        return isNaN(num) ? value : num;
      });
    filteredArr.sort((a, b) => a - b);
    const len = filteredArr.length;
    if (len === 0) {
      return 0;
    }
    // Calculate median
    let median;
    if (len % 2 === 1) {
      median = filteredArr[Math.floor(len / 2)];
    } else {
      const mid1 = filteredArr[len / 2 - 1];
      const mid2 = filteredArr[len / 2];
      median = (mid1 + mid2) / 2;
    }
    return typeof median === "number" ? parseFloat(median.toFixed(2)) : median;
  }
  function countCalc(arr) {
    const totalArrayLength = arr.length;
    const filteredArr = arr
      .filter((value) => {
        if (typeof value === "string") {
          return value.trim() && value !== "";
        }
        return value !== 0 && value !== undefined;
      })
      .map((value) => Number(value));
    if (filteredArr.length === 0) {
      return 0;
    }
    const nonEmptyCount = filteredArr.length;
    const percentage = ((nonEmptyCount / totalArrayLength) * 100).toFixed(2);
    return percentage;
  }

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const filterArray = (dataValues) => {
    // Start Median Cal
    // P20
    p20Median = dataValues.map((items) => items.p20);
    // P15
    p15Median = dataValues.map((items) => items.p15);
    // P16
    p16Median = dataValues.map((items) => items.p16);
    // P18
    p18Median = dataValues.map((items) => items.p18_hr150);
    // P11
    p11Median = dataValues.map((items) => items.p11);
    // SNF
    snfMedian = dataValues.map((items) => items.snf_n5);
    // l1_ligno_50
    ligno50Median = dataValues.map((items) => items.l1_ligno_50);
    // ligno
    lignoMedian = dataValues.map((items) => items.ligno);
    // gn1
    gn1Median = dataValues.map((items) => items.gn1);
    // CF
    cfMedian = dataValues.map((items) => items.cf);
    // G1
    g1Median = dataValues.map((items) => items.g1);
    // ac2_ac3
    ac2_ac3Median = dataValues.map((items) => items.ac2_ac3);
    // ac1
    ac1Median = dataValues.map((items) => items.ac1);
    // ac5_ac6
    ac5_ac6Median = dataValues.map((items) => items.ac5_ac6);

    // DF2
    df2Median = dataValues.map((items) => items.df2);
    // DF1
    df1Median = dataValues.map((items) => items.df1);
    // DF3
    df3Median = dataValues.map((items) => items.df3);
    // Ar1
    ar1Median = dataValues.map((items) => items.ar1);
    // Ar2
    ar2Median = dataValues.map((items) => items.ar2);
    // Ar3
    ar3Median = dataValues.map((items) => items.ar3);
    // Ar4
    ar4Median = dataValues.map((items) => items.ar4);
    // Ar5
    ar5Median = dataValues.map((items) => items.ar5);
    // Ar6
    ar6Median = dataValues.map((items) => items.ar6);
    // Ar7
    ar7Median = dataValues.map((items) => items.ar7);
    // Cr1
    cr_1Median = dataValues.map((items) => items.cr_1);
    // b29_p23
    b29_p23Median = dataValues.map((items) => items.b29_p23);
    // p27_R650
    p27_r650Median = dataValues.map((items) => items.p27_r650);
    // a15_p24
    a15_p24Median = dataValues.map((items) => items.a15_p24);
    // Pr1
    pr1Median = dataValues.map((items) => items.pr1);
    // gn2
    gn2Median = dataValues.map((items) => items.gn2);
    // N43
    n43Median = dataValues.map((items) => items.n43);
    // Gly
    glyMedian = dataValues.map((items) => items.gly);
    // ca
    c2Median = dataValues.map((items) => items.ca);
    // Ac6
    ac6Median = dataValues.map((items) => items.ac6);
    // p25_a19
    p25_a19Median = dataValues.map((items) => items.p25_a19);
    // p26
    p26Median = dataValues.map((items) => items.p26);
    // VMA1
    vma1Median = dataValues.map((items) => items.vma1);
    // VMA2
    vma2Median = dataValues.map((items) => items.vma2);
    // Others
    othersMedian = dataValues.map((items) => items.others);
  };

  filterArray(props.value === 0 ? PseriesDataArray : snfSeriesDataArray);
  // Median Calculation Sec
  const p20MedianVal = evenNumber(p20Median);
  const p15MedianVal = evenNumber(p15Median);
  const p16MedianVal = evenNumber(p16Median);
  const p18MedianVal = evenNumber(p18Median);
  const p11MedianVal = evenNumber(p11Median);
  const snfMedianVal = evenNumber(snfMedian);
  const ligno50MedianVal = evenNumber(ligno50Median);
  const lignoMedianVal = evenNumber(lignoMedian);
  const gn1MedianVal = evenNumber(gn1Median);
  const cfMedianVal = evenNumber(cfMedian);
  const g1MedianVal = evenNumber(g1Median);
  const ac2_ac3MedianVal = evenNumber(ac2_ac3Median);
  const ac1MedianVal = evenNumber(ac1Median);
  const ac5_ac6MedianVal = evenNumber(ac5_ac6Median);
  const df2MedianVal = evenNumber(df2Median);
  const df1MedianVal = evenNumber(df1Median);
  const df3MedianVal = evenNumber(df3Median);
  const ar1MedianVal = evenNumber(ar1Median);
  const ar2MedianVal = evenNumber(ar2Median);
  const ar3MedianVal = evenNumber(ar3Median);
  const ar4MedianVal = evenNumber(ar4Median);
  const ar5MedianVal = evenNumber(ar5Median);
  const ar6MedianVal = evenNumber(ar6Median);
  const ar7MedianVal = evenNumber(ar7Median);
  const cr_1MedianVal = evenNumber(cr_1Median);
  const b29_p23MedianVal = evenNumber(b29_p23Median);
  const p27_r650MedianVal = evenNumber(p27_r650Median);
  const a15_p24MedianVal = evenNumber(a15_p24Median);
  const pr1MedianVal = evenNumber(pr1Median);
  const gn2MedianVal = evenNumber(gn2Median);
  const n43MedianVal = evenNumber(n43Median);
  const glyMedianVal = evenNumber(glyMedian);
  const c2MedianVal = evenNumber(c2Median);
  const ac6MedianVal = evenNumber(ac6Median);
  const p25_a19MedianVal = evenNumber(p25_a19Median);
  const p26MedianVal = evenNumber(p26Median);
  const vma1MedianVal = evenNumber(vma1Median);
  const vma2MedianVal = evenNumber(vma2Median);
  const othersMedianVal = evenNumber(othersMedian);
  // STANDARD DEVIATION
  const p20_Standcalc = calculateStandardDeviation(p20Median);
  const p15_Standcalc = calculateStandardDeviation(p15Median);
  const p16_Standcalc = calculateStandardDeviation(p16Median);
  const p18_Standcalc = calculateStandardDeviation(p18Median);
  const p11_Standcalc = calculateStandardDeviation(p11Median);
  const snf_Standcalc = calculateStandardDeviation(snfMedian);
  const ligno50_Standcalc = calculateStandardDeviation(ligno50Median);
  const ligno_Standcalc = calculateStandardDeviation(lignoMedian);
  const gn1_Standcalc = calculateStandardDeviation(gn1Median);
  const cf_Standcalc = calculateStandardDeviation(cfMedian);
  const g1_Standcalc = calculateStandardDeviation(g1Median);
  const ac2_ac3_Standcalc = calculateStandardDeviation(ac2_ac3Median);
  const ac1_Standcalc = calculateStandardDeviation(ac1Median);
  const ac5_ac6_Standcalc = calculateStandardDeviation(ac5_ac6Median);
  const df1_Standcalc = calculateStandardDeviation(df1Median);
  const df2_Standcalc = calculateStandardDeviation(df2Median);
  const df3_Standcalc = calculateStandardDeviation(df3Median);
  const ar1_Standcalc = calculateStandardDeviation(ar1Median);
  const ar2_Standcalc = calculateStandardDeviation(ar2Median);
  const ar3_Standcalc = calculateStandardDeviation(ar3Median);
  const ar4_Standcalc = calculateStandardDeviation(ar4Median);
  const ar5_Standcalc = calculateStandardDeviation(ar5Median);
  const ar6_Standcalc = calculateStandardDeviation(ar6Median);
  const ar7_Standcalc = calculateStandardDeviation(ar7Median);
  const cr1_Standcalc = calculateStandardDeviation(cr_1Median);
  const b29_p23_Standcalc = calculateStandardDeviation(b29_p23Median);
  const p27_r650_Standcalc = calculateStandardDeviation(p27_r650Median);
  const a15_p24_Standcalc = calculateStandardDeviation(a15_p24Median);
  const pr1_Standcalc = calculateStandardDeviation(pr1Median);
  const gn2_Standcalc = calculateStandardDeviation(gn2Median);
  const n43_Standcalc = calculateStandardDeviation(n43Median);
  const gly_Standcalc = calculateStandardDeviation(glyMedian);
  const c2_Standcalc = calculateStandardDeviation(c2Median);
  const ac6_Standcalc = calculateStandardDeviation(ac6Median);
  const p25_Standcalc = calculateStandardDeviation(p25_a19Median);
  const p26_Standcalc = calculateStandardDeviation(p26Median);
  const vma1_Standcalc = calculateStandardDeviation(vma1Median);
  const vma2_Standcalc = calculateStandardDeviation(vma2Median);
  const others_Standcalc = calculateStandardDeviation(othersMedian);
  // Count Sec
  const p20countVal = countCalc(p20Median);
  const p15countVal = countCalc(p15Median);
  const p16countVal = countCalc(p16Median);
  const p18countVal = countCalc(p18Median);
  const p11countVal = countCalc(p11Median);
  const snfcountVal = countCalc(snfMedian);
  const ligno50countVal = countCalc(ligno50Median);
  const lignoCountVal = countCalc(lignoMedian);
  const gn1countVal = countCalc(gn1Median);
  const cfcountVal = countCalc(cfMedian);
  const g1countVal = countCalc(g1Median);
  const ac2_ac3countVal = countCalc(ac2_ac3Median);
  const ac1countVal = countCalc(ac1Median);
  const ac5_ac6countVal = countCalc(ac5_ac6Median);
  const df2countVal = countCalc(df2Median);
  const df1countVal = countCalc(df1Median);
  const df3countVal = countCalc(df3Median);
  const ar1countVal = countCalc(ar1Median);
  const ar2countVal = countCalc(ar2Median);
  const ar3countVal = countCalc(ar3Median);
  const ar4countVal = countCalc(ar4Median);
  const ar5countVal = countCalc(ar5Median);
  const ar6countVal = countCalc(ar6Median);
  const ar7countVal = countCalc(ar7Median);
  const cr_1countVal = countCalc(cr_1Median);
  const b29_p23countVal = countCalc(b29_p23Median);
  const p27_r650countVal = countCalc(p27_r650Median);
  const a15_p24countVal = countCalc(a15_p24Median);
  const pr1countVal = countCalc(pr1Median);
  const gn2countVal = countCalc(gn2Median);
  const n43countVal = countCalc(n43Median);
  const glycountVal = countCalc(glyMedian);
  const c2countVal = countCalc(c2Median);
  const ac6countVal = countCalc(ac6Median);
  const p25_a19countVal = countCalc(p25_a19Median);
  const p26countVal = countCalc(p26Median);
  const vma1countVal = countCalc(vma1Median);
  const vma2countVal = countCalc(vma2Median);
  const otherscountVal = countCalc(othersMedian);

  // Lower  Limit Sec
  const p20LowerLimit = lowerLimit(p20MedianVal, p20_Standcalc);
  const p15LowerLimit = lowerLimit(p15MedianVal, p15_Standcalc);
  const p16LowerLimit = lowerLimit(p16MedianVal, p16_Standcalc);
  const p18LowerLimit = lowerLimit(p18MedianVal, p18_Standcalc);
  const p11LowerLimit = lowerLimit(p11MedianVal, p11_Standcalc);
  const snfLowerLimit = lowerLimit(snfMedianVal, snf_Standcalc);
  const ligno50LowerLimit = lowerLimit(ligno50MedianVal, ligno50_Standcalc);
  const lignoLowerLimit = lowerLimit(lignoMedianVal, ligno_Standcalc);
  const gn1LowerLimit = lowerLimit(gn1MedianVal, gn1_Standcalc);
  const cfLowerLimit = lowerLimit(cfMedianVal, cf_Standcalc);
  const g1LowerLimit = lowerLimit(g1MedianVal, g1_Standcalc);
  const ac2_ac3LowerLimit = lowerLimit(ac2_ac3MedianVal, ac2_ac3_Standcalc);
  const ac1LowerLimit = lowerLimit(ac1MedianVal, ac1_Standcalc);
  const ac5_ac6LowerLimit = lowerLimit(ac5_ac6MedianVal, ac5_ac6_Standcalc);
  const df2LowerLimit = lowerLimit(df2MedianVal, df2_Standcalc);
  const df1LowerLimit = lowerLimit(df1MedianVal, df1_Standcalc);
  const df3LowerLimit = lowerLimit(df3MedianVal, df3_Standcalc);
  const ar1LowerLimit = lowerLimit(ar1MedianVal, ar1_Standcalc);
  const ar2LowerLimit = lowerLimit(ar2MedianVal, ar2_Standcalc);
  const ar3LowerLimit = lowerLimit(ar3MedianVal, ar3_Standcalc);
  const ar4LowerLimit = lowerLimit(ar4MedianVal, ar4_Standcalc);
  const ar5LowerLimit = lowerLimit(ar5MedianVal, ar5_Standcalc);
  const ar6LowerLimit = lowerLimit(ar6MedianVal, ar6_Standcalc);
  const ar7LowerLimit = lowerLimit(ar7MedianVal, ar7_Standcalc);
  const cr_1LowerLimit = lowerLimit(cr_1MedianVal, cr1_Standcalc);
  const b29_p23LowerLimit = lowerLimit(b29_p23MedianVal, b29_p23_Standcalc);
  const p27_r650LowerLimit = lowerLimit(p27_r650MedianVal, p27_r650_Standcalc);
  const a15_p24LowerLimit = lowerLimit(a15_p24MedianVal, a15_p24_Standcalc);
  const pr1LowerLimit = lowerLimit(pr1MedianVal, pr1_Standcalc);
  const gn2LowerLimit = lowerLimit(gn2MedianVal, gn2_Standcalc);
  const n43LowerLimit = lowerLimit(n43MedianVal, n43_Standcalc);
  const glyLowerLimit = lowerLimit(glyMedianVal, gly_Standcalc);
  const c2LowerLimit = lowerLimit(c2MedianVal, c2_Standcalc);
  const ac6LowerLimit = lowerLimit(ac6MedianVal, ac6_Standcalc);
  const p25_a19LowerLimit = lowerLimit(p25_a19MedianVal, p25_Standcalc);
  const p26LowerLimit = lowerLimit(p26MedianVal, p26_Standcalc);
  const vma1LowerLimit = lowerLimit(vma1MedianVal, vma1_Standcalc);
  const vma2LowerLimit = lowerLimit(vma2MedianVal, vma2_Standcalc);
  const othersLowerLimit = lowerLimit(othersMedianVal, others_Standcalc);

  // Upper Limit Sec
  const p20upperLimit = upperLimit(p20MedianVal, p20_Standcalc);
  const p15upperLimit = upperLimit(p15MedianVal, p15_Standcalc);
  const p16upperLimit = upperLimit(p16MedianVal, p16_Standcalc);
  const p18upperLimit = upperLimit(p18MedianVal, p18_Standcalc);
  const p11upperLimit = upperLimit(p11MedianVal, p11_Standcalc);
  const snfupperLimit = upperLimit(snfMedianVal, snf_Standcalc);
  const ligno50upperLimit = upperLimit(ligno50MedianVal, ligno50_Standcalc);
  const lignoupperLimit = upperLimit(lignoMedianVal, ligno_Standcalc);
  const gn1upperLimit = upperLimit(gn1MedianVal, gn1_Standcalc);
  const cfupperLimit = upperLimit(cfMedianVal, cf_Standcalc);
  const g1upperLimit = upperLimit(g1MedianVal, g1_Standcalc);
  const ac2_ac3upperLimit = upperLimit(ac2_ac3MedianVal, ac2_ac3_Standcalc);
  const ac1upperLimit = upperLimit(ac1MedianVal, ac1_Standcalc);
  const ac5_ac6upperLimit = upperLimit(ac5_ac6MedianVal, ac5_ac6_Standcalc);
  const df2upperLimit = upperLimit(df2MedianVal, df2_Standcalc);
  const df1upperLimit = upperLimit(df1MedianVal, df1_Standcalc);
  const df3upperLimit = upperLimit(df3MedianVal, df3_Standcalc);
  const ar1upperLimit = upperLimit(ar1MedianVal, ar1_Standcalc);
  const ar2upperLimit = upperLimit(ar2MedianVal, ar2_Standcalc);
  const ar3upperLimit = upperLimit(ar3MedianVal, ar3_Standcalc);
  const ar4upperLimit = upperLimit(ar4MedianVal, ar4_Standcalc);
  const ar5upperLimit = upperLimit(ar5MedianVal, ar5_Standcalc);
  const ar6upperLimit = upperLimit(ar6MedianVal, ar6_Standcalc);
  const ar7upperLimit = upperLimit(ar7MedianVal, ar7_Standcalc);
  const cr_1upperLimit = upperLimit(cr_1MedianVal, cr1_Standcalc);
  const b29_p23upperLimit = upperLimit(b29_p23MedianVal, b29_p23_Standcalc);
  const p27_r650upperLimit = upperLimit(p27_r650MedianVal, p27_r650_Standcalc);
  const a15_p24upperLimit = upperLimit(a15_p24MedianVal, a15_p24_Standcalc);
  const pr1upperLimit = upperLimit(pr1MedianVal, pr1_Standcalc);
  const gn2upperLimit = upperLimit(gn2MedianVal, gn2_Standcalc);
  const n43upperLimit = upperLimit(n43MedianVal, n43_Standcalc);
  const glyupperLimit = upperLimit(glyMedianVal, gly_Standcalc);
  const c2upperLimit = upperLimit(c2MedianVal, c2_Standcalc);
  const ac6upperLimit = upperLimit(ac6MedianVal, ac6_Standcalc);
  const p25_a19upperLimit = upperLimit(p25_a19MedianVal, p25_Standcalc);
  const p26upperLimit = upperLimit(p26MedianVal, p26_Standcalc);
  const vma1upperLimit = upperLimit(vma1MedianVal, vma1_Standcalc);
  const vma2upperLimit = upperLimit(vma2MedianVal, vma2_Standcalc);
  const othersupperLimit = upperLimit(othersMedianVal, others_Standcalc);

  const medianValues = {
    p20MedianVal: Number(p20MedianVal),
    p15MedianVal: Number(p15MedianVal),
    p16MedianVal: Number(p16MedianVal),
    p18MedianVal: Number(p18MedianVal),
    p11MedianVal: Number(p11MedianVal),
    p27_r650MedianVal: Number(p27_r650MedianVal),
    p25_a19MedianVal: Number(p25_a19MedianVal),
    p26MedianVal: Number(p26MedianVal),
    pr1MedianVal: Number(pr1MedianVal),
    snfMedianVal: Number(snfMedianVal),
    ligno50MedianVal: Number(ligno50MedianVal),
    lignoMedianVal: Number(lignoMedianVal),
    gn1MedianVal: Number(gn1MedianVal),
    gn2MedianVal: Number(gn2MedianVal),
    g1MedianVal: Number(g1MedianVal),
    glyMedianVal: Number(glyMedianVal),
    df1MedianVal: Number(df1MedianVal),
    df2MedianVal: Number(df2MedianVal),
    df3MedianVal: Number(df3MedianVal),
    cfMedianVal: Number(cfMedianVal),
    cr_1MedianVal: Number(cr_1MedianVal),
    c2MedianVal: Number(c2MedianVal),
    ac1MedianVal: Number(ac1MedianVal),
    ac2_ac3MedianVal: Number(ac2_ac3MedianVal),
    ac5_ac6MedianVal: Number(ac5_ac6MedianVal),
    ac6MedianVal: Number(ac6MedianVal),
    ar1MedianVal: Number(ar1MedianVal),
    ar2MedianVal: Number(ar2MedianVal),
    ar3MedianVal: Number(ar3MedianVal),
    ar4MedianVal: Number(ar4MedianVal),
    ar5MedianVal: Number(ar5MedianVal),
    ar6MedianVal: Number(ar6MedianVal),
    ar7MedianVal: Number(ar7MedianVal),
    a15_p24MedianVal: Number(a15_p24MedianVal),
    b29_p23MedianVal: Number(b29_p23MedianVal),
    n43MedianVal: Number(n43MedianVal),
    vma1MedianVal: Number(vma1MedianVal),
    vma2MedianVal: Number(vma2MedianVal),
    othersMedianVal: Number(othersMedianVal),
  };
  const standardDeviation = {
    p20MedianVal: p20_Standcalc,
    p15MedianVal: p15_Standcalc,
    p16MedianVal: p16_Standcalc,
    p18MedianVal: p18_Standcalc,
    p11MedianVal: p11_Standcalc,
    p27_r650MedianVal: p27_r650_Standcalc,
    p25_a19MedianVal: p25_Standcalc,
    p26MedianVal: p26_Standcalc,
    pr1MedianVal: pr1_Standcalc,
    snfMedianVal: snf_Standcalc,
    ligno50MedianVal: ligno50_Standcalc,
    lignoMedianVal: ligno_Standcalc,
    gn1MedianVal: gn1_Standcalc,
    gn2MedianVal: gn2_Standcalc,
    g1MedianVal: g1_Standcalc,
    glyMedianVal: gly_Standcalc,
    df1MedianVal: df1_Standcalc,
    df2MedianVal: df2_Standcalc,
    df3MedianVal: df3_Standcalc,
    cfMedianVal: cf_Standcalc,
    cr_1MedianVal: cr1_Standcalc,
    c2MedianVal: c2_Standcalc,
    ac1MedianVal: ac1_Standcalc,
    ac2_ac3MedianVal: ac2_ac3_Standcalc,
    ac5_ac6MedianVal: ac5_ac6_Standcalc,
    ac6MedianVal: ac6_Standcalc,
    ar1MedianVal: ar1_Standcalc,
    ar2MedianVal: ar2_Standcalc,
    ar3MedianVal: ar3_Standcalc,
    ar4MedianVal: ar4_Standcalc,
    ar5MedianVal: ar5_Standcalc,
    ar6MedianVal: ar6_Standcalc,
    ar7MedianVal: ar7_Standcalc,
    a15_p24MedianVal: a15_p24_Standcalc,
    b29_p23MedianVal: b29_p23_Standcalc,
    n43MedianVal: n43_Standcalc,
    vma1MedianVal: vma1_Standcalc,
    vma2MedianVal: vma2_Standcalc,
    othersMedianVal: others_Standcalc,
  };

  const lowerLimitCalc = {
    p20MedianVal: p20LowerLimit,
    p15MedianVal: p15LowerLimit,
    p16MedianVal: p16LowerLimit,
    p18MedianVal: p18LowerLimit,
    p11MedianVal: p11LowerLimit,
    p27_r650MedianVal: p27_r650LowerLimit,
    p25_a19MedianVal: p25_a19LowerLimit,
    p26MedianVal: p26LowerLimit,
    pr1MedianVal: pr1LowerLimit,
    snfMedianVal: snfLowerLimit,
    ligno50MedianVal: ligno50LowerLimit,
    lignoMedianVal: lignoLowerLimit,
    gn1MedianVal: gn1LowerLimit,
    gn2MedianVal: gn2LowerLimit,
    g1MedianVal: g1LowerLimit,
    glyMedianVal: glyLowerLimit,
    df1MedianVal: df1LowerLimit,
    df2MedianVal: df2LowerLimit,
    df3MedianVal: df3LowerLimit,
    cfMedianVal: cfLowerLimit,
    cr_1MedianVal: cr_1LowerLimit,
    c2MedianVal: c2LowerLimit,
    ac1MedianVal: ac1LowerLimit,
    ac2_ac3MedianVal: ac2_ac3LowerLimit,
    ac5_ac6MedianVal: ac5_ac6LowerLimit,
    ac6MedianVal: ac6LowerLimit,
    ar1MedianVal: ar1LowerLimit,
    ar2MedianVal: ar2LowerLimit,
    ar3MedianVal: ar3LowerLimit,
    ar4MedianVal: ar4LowerLimit,
    ar5MedianVal: ar5LowerLimit,
    ar6MedianVal: ar6LowerLimit,
    ar7MedianVal: ar7LowerLimit,
    a15_p24MedianVal: a15_p24LowerLimit,
    b29_p23MedianVal: b29_p23LowerLimit,
    n43MedianVal: n43LowerLimit,
    vma1MedianVal: vma1LowerLimit,
    vma2MedianVal: vma2LowerLimit,
    othersMedianVal: othersLowerLimit,
  };
  const upperLimitCalc = {
    p20MedianVal: p20upperLimit,
    p15MedianVal: p15upperLimit,
    p16MedianVal: p16upperLimit,
    p18MedianVal: p18upperLimit,
    p11MedianVal: p11upperLimit,
    p27_r650MedianVal: p27_r650upperLimit,
    p25_a19MedianVal: p25_a19upperLimit,
    p26MedianVal: p26upperLimit,
    pr1MedianVal: pr1upperLimit,
    snfMedianVal: snfupperLimit,
    ligno50MedianVal: ligno50upperLimit,
    lignoMedianVal: lignoupperLimit,
    gn1MedianVal: gn1upperLimit,
    gn2MedianVal: gn2upperLimit,
    g1MedianVal: g1upperLimit,
    glyMedianVal: glyupperLimit,
    df1MedianVal: df1upperLimit,
    df2MedianVal: df2upperLimit,
    df3MedianVal: df3upperLimit,
    cfMedianVal: cfupperLimit,
    cr_1MedianVal: cr_1upperLimit,
    c2MedianVal: c2upperLimit,
    ac1MedianVal: ac1upperLimit,
    ac2_ac3MedianVal: ac2_ac3upperLimit,
    ac5_ac6MedianVal: ac5_ac6upperLimit,
    ac6MedianVal: ac6upperLimit,
    ar1MedianVal: ar1upperLimit,
    ar2MedianVal: ar2upperLimit,
    ar3MedianVal: ar3upperLimit,
    ar4MedianVal: ar4upperLimit,
    ar5MedianVal: ar5upperLimit,
    ar6MedianVal: ar6upperLimit,
    ar7MedianVal: ar7upperLimit,
    a15_p24MedianVal: a15_p24upperLimit,
    b29_p23MedianVal: b29_p23upperLimit,
    n43MedianVal: n43upperLimit,
    vma1MedianVal: vma1upperLimit,
    vma2MedianVal: vma2upperLimit,
    othersMedianVal: othersupperLimit,
  };
  const countCalcSec = {
    p20MedianVal: p20countVal,
    p15MedianVal: p15countVal,
    p16MedianVal: p16countVal,
    p18MedianVal: p18countVal,
    p11MedianVal: p11countVal,
    p27_r650MedianVal: p27_r650countVal,
    p25_a19MedianVal: p25_a19countVal,
    p26MedianVal: p26countVal,
    pr1MedianVal: pr1countVal,
    snfMedianVal: snfcountVal,
    ligno50MedianVal: ligno50countVal,
    lignoMedianVal: lignoCountVal,
    gn1MedianVal: gn1countVal,
    gn2MedianVal: gn2countVal,
    g1MedianVal: g1countVal,
    glyMedianVal: glycountVal,
    df1MedianVal: df1countVal,
    df2MedianVal: df2countVal,
    df3MedianVal: df3countVal,
    cfMedianVal: cfcountVal,
    cr_1MedianVal: cr_1countVal,
    c2MedianVal: c2countVal,
    ac1MedianVal: ac1countVal,
    ac2_ac3MedianVal: ac2_ac3countVal,
    ac5_ac6MedianVal: ac5_ac6countVal,
    ac6MedianVal: ac6countVal,
    ar1MedianVal: ar1countVal,
    ar2MedianVal: ar2countVal,
    ar3MedianVal: ar3countVal,
    ar4MedianVal: ar4countVal,
    ar5MedianVal: ar5countVal,
    ar6MedianVal: ar6countVal,
    ar7MedianVal: ar7countVal,
    a15_p24MedianVal: a15_p24countVal,
    b29_p23MedianVal: b29_p23countVal,
    n43MedianVal: n43countVal,
    vma1MedianVal: vma1countVal,
    vma2MedianVal: vma2countVal,
    othersMedianVal: otherscountVal,
  };
  const graphData = [];
  for (const key in medianValues) {
    const name = key.replace("MedianVal", "");
    if (
      medianValues[key] === 0 &&
      lowerLimitCalc[key] === 0 &&
      upperLimitCalc[key] === 0
    ) {
    } else {
      const obj = {
        Name: name.toLocaleUpperCase(),
        Formulation: medianValues[key],
        "Lower Limit": lowerLimitCalc[key],
        "Upper Limit": upperLimitCalc[key],
      };
      graphData.push(obj);
    }
  }
  const medians = [
    { id: 1, header: "Suggested Formulation", ...medianValues },
    ...(roleSection
      ? [{ id: 2, header: "Standard Deviation", ...standardDeviation }]
      : []),
    { id: 3, header: "Lower Limit", ...lowerLimitCalc },
    { id: 4, header: "Upper Limit", ...upperLimitCalc },
    ...(roleSection ? [{ id: 5, header: "Count (%)", ...countCalcSec }] : []),
  ];

  const columns = [
    {
      field: "header",
      headerName: "Calculation",
      width: 230,
      editable: false,
    },
    {
      field: "p20MedianVal",
      headerName: "P20",
      width: 80,
      editable: false,
    },
    {
      field: "p15MedianVal",
      headerName: "P15",
      width: 80,
      editable: false,
    },

    {
      field: "p16MedianVal",
      headerName: "P16",
      width: 80,
      editable: false,
    },
    {
      field: "p18MedianVal",
      headerName: "P18/HR150",
      width: 80,
      editable: false,
    },
    {
      field: "p11MedianVal",
      headerName: "P11",
      width: 80,
      editable: false,
    },
    {
      field: "p27_r650MedianVal",
      headerName: "P27/R650",
      width: 130,
      editable: false,
    },
    {
      field: "p25_a19MedianVal",
      headerName: "P25/A19",
      width: 90,
      editable: false,
    },
    {
      field: "p26MedianVal",
      headerName: "P26",
      width: 90,
      editable: false,
    },

    {
      field: "pr1MedianVal",
      headerName: "PR1",
      width: 90,
      editable: false,
    },

    {
      field: "snfMedianVal",
      headerName: "SNF/N5",
      type: "number",
      width: 80,
      editable: false,
    },
    {
      field: "ligno50MedianVal",
      headerName: "L1(Ligno50)",
      type: "number",
      width: 120,
      editable: false,
    },
    {
      field: "lignoMedianVal",
      headerName: "Ligno",
      width: 80,
      editable: false,
    },

    {
      field: "gn1MedianVal",
      headerName: "GN1",
      width: 80,
      editable: false,
    },
    {
      field: "gn2MedianVal",
      headerName: "Gn2",
      width: 90,
      editable: false,
    },
    {
      field: "g1MedianVal",
      headerName: "G1",
      width: 90,
      editable: false,
    },
    {
      field: "glyMedianVal",
      headerName: "Gly",
      width: 90,
      editable: false,
    },
    {
      field: "df1MedianVal",
      headerName: "DF1",
      width: 90,
      editable: false,
    },
    {
      field: "df2MedianVal",
      headerName: "Df2",
      width: 90,
      editable: false,
    },

    {
      field: "df3MedianVal",
      headerName: "DF3",
      width: 90,
      editable: false,
    },
    {
      field: "cfMedianVal",
      headerName: "CF",
      width: 90,
      editable: false,
    },
    {
      field: "cr_1MedianVal",
      headerName: "CR/1",
      width: 90,
      editable: false,
    },
    {
      field: "c2MedianVal",
      headerName: "CA",
      width: 90,
      editable: false,
    },
    {
      field: "ac1MedianVal",
      headerName: "AC1",
      width: 90,
      editable: false,
    },
    {
      field: "ac2_ac3MedianVal",
      headerName: "AC2/AC3",
      width: 90,
      editable: false,
    },

    {
      field: "ac5_ac6MedianVal",
      headerName: "AC5/AC6",
      width: 90,
      editable: false,
    },
    {
      field: "ac6MedianVal",
      headerName: "AC6",
      width: 90,
      editable: false,
    },
    {
      field: "ar1MedianVal",
      headerName: "AR1",
      width: 90,
      editable: false,
    },
    {
      field: "ar2MedianVal",
      headerName: "AR2",
      width: 90,
      editable: false,
    },
    {
      field: "ar3MedianVal",
      headerName: "AR3",
      width: 90,
      editable: false,
    },
    {
      field: "ar4MedianVal",
      headerName: "AR4",
      width: 90,
      editable: false,
    },
    {
      field: "ar5MedianVal",
      headerName: "AR5",
      width: 90,
      editable: false,
    },
    {
      field: "ar6MedianVal",
      headerName: "AR6",
      width: 90,
      editable: false,
    },
    {
      field: "ar7MedianVal",
      headerName: "AR7",
      width: 90,
      editable: false,
    },
    {
      field: "a15_p24MedianVal",
      headerName: "A15/P24",
      width: 130,
      editable: false,
    },
    {
      field: "b29_p23MedianVal",
      headerName: "B29/P23",
      width: 130,
      editable: false,
    },
    {
      field: "n43MedianVal",
      headerName: "N43",
      width: 90,
      editable: false,
    },
    {
      field: "vma1MedianVal",
      headerName: "VMA1",
      width: 90,
      editable: false,
    },
    {
      field: "vma2MedianVal",
      headerName: "VMA2",
      width: 90,
      editable: false,
    },
    {
      field: "othersMedianVal",
      headerName: "Others",
      width: 130,
      editable: false,
    },
  ];
  function CustomizedAxisTick(props) {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={5}
          dx={-5} // Move the text 10 units to the left
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setShowPdf(false),
  });
  const pdftSection = () => {
    setShowPdf(true);
    setTimeout(() => {
      handlePrint();
    }, 100);
  };
  const maxMedian = Math.max(
    ...graphData.map((entry) =>
      !isNaN(entry["Formulation"]) ? entry["Formulation"] : 0
    )
  );
  const maxUL = Math.max(
    ...graphData.map((entry) =>
      !isNaN(entry["Upper Limit"]) ? entry["Upper Limit"] : 0
    )
  );

  return (
    <div>
      {/* Receipe Section */}
      <div style={{ marginBottom: 20, display: "flex", alignItems: "center" }}>
        <button
          style={{
            border: "none",
            cursor: "pointer",
            background: "none",
            padding: 10,
            fontWeight: "400",
            fontSize: "16px",
            color: activeButton === "table" ? "white" : "black",
            backgroundColor: activeButton === "table" ? "gray" : "white",
          }}
          onClick={() => handleButtonClick("table")}
        >
          Table View
        </button>
        <div stlye={{ marginLeft: 20 }}>
          <button
            style={{
              border: "none",
              cursor: "pointer",
              background: "none",
              padding: 10,
              fontWeight: "400",
              fontSize: "16px",
              color: activeButton === "graph" ? "white" : "black",
              backgroundColor: activeButton === "graph" ? "gray" : "white",
            }}
            onClick={() => handleButtonClick("graph")}
          >
            Graphical View
          </button>
        </div>
        {activeButton === "table" && props.value === 0 && (
          <div style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              endIcon={<DownloadIcon />}
              onClick={pdftSection}
            >
              PCE Pdf
            </Button>
          </div>
        )}
        {activeButton === "table" && props.value === 1 && (
          <div style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              endIcon={<DownloadIcon />}
              onClick={pdftSection}
            >
              SNF Pdf
            </Button>
          </div>
        )}
      </div>
      {showPdf ? (
        <React.Fragment>
          <div id="cust-print" style={{ display: "none", marginTop: 20 }}>
            <div
              ref={printRef}
              style={{
                margin: "10px",
                width: "1150px",
              }}
            >
              <PreviewPdf ref={printRef} data={graphData} />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {activeButton === "table" && (
        <Grid container>
          <Grid item xl={12} xs={12}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={medians}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowId={(row) => row.id}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {activeButton === "graph" && (
        <Grid container spacing={1}>
          <Grid item xl={12} xs={12}>
            <ResponsiveContainer width="100%" height={500}>
              <ComposedChart
                data={graphData}
                height={600}
                width={500}
                margin={{
                  top: 50,
                  right: 50,
                  bottom: 50,
                  left: 50,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  dataKey="Name"
                  tick={<CustomizedAxisTick />}
                  interval={0} // Display all labels without interval
                  angle={-45} // Rotate labels to prevent overlapping
                  textAnchor="end" // Align rotated labels properly
                  height={80}
                  label={{
                    // value: 'X Axis Label', // The label text
                    position: "insideBottomRight", // Adjust the position here
                    offset: 0, // Adjust the offset (positive for moving right, negative for moving left)
                    style: {
                      // Additional styles for the label
                      fontSize: "14px",
                      fontWeight: "bold",
                    },
                  }}
                />
                <YAxis
                  yAxisId="left"
                  label={{
                    value: "Formulation",
                    angle: -90,
                    position: "insideLeft",
                  }}
                  domain={[0, maxMedian]}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: "Lower Limit & Upper Limit",
                    angle: -90,
                    position: "insideRight",
                  }}
                  domain={[0, maxUL]}
                />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="Formulation"
                  barSize={20}
                  fill="#413ea0"
                  yAxisId="left"
                ></Bar>
                <Line
                  type="monotone"
                  dataKey="Lower Limit"
                  stroke="#ff7300"
                  yAxisId="right"
                ></Line>
                <Line
                  type="monotone"
                  dataKey="Upper Limit"
                  stroke="#129cf3"
                  yAxisId="right"
                ></Line>
              </ComposedChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
const ReceipeSection = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Formulation PCE" {...a11yProps(0)} />
            <Tab label="Formulation SNF" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <FilterCalcTable value={value} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <FilterCalcTable value={value} />
        </CustomTabPanel>
      </Box>
    </div>
  );
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdvancedSearch = () => {
  const [value, setValue] = React.useState(0);
  const [flg, setFlg] = useState(false);
  const [, setIsHover] = useState(false);
  const filterArrayData = useRecoilValue(filterDataArray);
  const isTabDisabled = filterArrayData.length === 0;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <CardComponent />
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Trial Data" {...a11yProps(0)} />
              <Tab
                label="Formula/Receipe Suggestion"
                {...a11yProps(1)}
                onClick={() => setFlg(!flg)}
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                disabled={filterArrayData.length === 0}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <AdvancedSearchTable isTabDisabled={isTabDisabled} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ReceipeSection />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};
export default AdvancedSearch;
